// CheckoutCancel.js

import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CheckoutCancel = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        fontFamily: 'Inter, sans-serif',
        color: '#2B2E34',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2rem',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Pagamento annullato
      </Typography>
      <Typography variant="body1" gutterBottom>
        Il tuo acquisto non è stato completato. Se hai riscontrato problemi, contatta il supporto.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/abbonamento')}
        sx={{ marginTop: '1rem' }}
      >
        Torna ai piani di abbonamento
      </Button>
    </Box>
  );
};

export default CheckoutCancel;
