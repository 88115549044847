// src/components/HomePage.js
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Header from '../Header';
import MobileMenu from '../MobileMenu';
import Footer from '../Footer';
import ServicesComponent from './Services';
import BenefitsComponent from './Benefits';
import FloatingMenu from '../FloatingMenu';
import Presentation from './Presentation'; // Importa il nuovo componente
import apiClient from '../../api/apiClient';
import { getAccessToken, getRefreshToken, removeTokens, setAccessToken } from '../../api/authUtils';
import { jwtDecode } from 'jwt-decode'; // Corretto l'importazione
import './styles/HomePage.css';

const HomePage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showFloatingMenu, setShowFloatingMenu] = useState(false);

  useEffect(() => {
    const checkAuthStatus = async () => {
      const accessToken = getAccessToken();
      const refreshToken = getRefreshToken();

      if (accessToken) {
        try {
          const decodedAccessToken = jwtDecode(accessToken);
          const currentTime = Date.now() / 1000;

          if (decodedAccessToken.exp && decodedAccessToken.exp > currentTime) {
            setIsLoggedIn(true);
          } else if (refreshToken) {
            try {
              const response = await apiClient.post('/accounts/token/refresh/', {
                refresh: refreshToken,
              });

              if (response.status === 200) {
                const data = response.data;
                setAccessToken(data.access);
                setIsLoggedIn(true);
              } else {
                removeTokens();
                setIsLoggedIn(false);
              }
            } catch (error) {
              console.error('Errore durante il refresh del token:', error);
              removeTokens();
              setIsLoggedIn(false);
            }
          } else {
            removeTokens();
            setIsLoggedIn(false);
          }
        } catch (error) {
          console.error('Errore durante la decodifica del token di accesso:', error);
          removeTokens();
          setIsLoggedIn(false);
        }
      } else if (refreshToken) {
        try {
          const response = await apiClient.post('/accounts/token/refresh/', {
            refresh: refreshToken,
          });

          if (response.status === 200) {
            const data = response.data;
            setAccessToken(data.access);
            setIsLoggedIn(true);
          } else {
            removeTokens();
            setIsLoggedIn(false);
          }
        } catch (error) {
          console.error('Errore durante il refresh del token:', error);
          removeTokens();
          setIsLoggedIn(false);
        }
      } else {
        setIsLoggedIn(false);
      }
    };

    checkAuthStatus();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowFloatingMenu(true);
      } else {
        setShowFloatingMenu(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  return (
    <div className="homepage">
      <Header
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />

      {mobileMenuOpen && (
        <MobileMenu 
          isLoggedIn={isLoggedIn} 
          setIsLoggedIn={setIsLoggedIn} // Aggiungi questa prop
          setMobileMenuOpen={setMobileMenuOpen} 
        />
      )}
      
      {showFloatingMenu && (
        <FloatingMenu
          isLoggedIn={isLoggedIn}
          handleLogout={() => {
            removeTokens();
            setIsLoggedIn(false);
          }}
        />
      )}

      <div className="container">
        <main>
          {/* Sezione 1 */}
          <motion.section
            className="section-one"
            initial="hidden"
            animate="visible"
            variants={sectionVariants}
          >
            <h1>
              Minerva AI. Dove
              <br />
              l’innovazione incontra
              <br />
              l’educazione
            </h1>

            <p>
              Assistente AI progettato per potenziare la metodologia didattica
              degli insegnanti
            </p>

            {/* Renderizzazione condizionale del pulsante */}
            {!isLoggedIn && (
              <a href="/welcomepage" className="try-minerva-button">
                Prova Minerva
              </a>
            )}

            {/* Inserisci il nuovo componente Presentation */}
            <Presentation />
          </motion.section>

          {/* Sezione 2 */}
          <motion.section
            className="section-two"
            initial="hidden"
            animate="visible"
            variants={sectionVariants}
          >
            <div className="text-content">
              <img src="/logo/logogrey.svg" alt="Logo" className="section-two-logo" />
              <h2>L’assistente ideale per insegnare.</h2>
              <p>
                Minerva AI Education semplifica l’insegnamento, creando idee,
                laboratori e piani didattici personalizzati in pochi secondi per
                migliorare l’apprendimento degli studenti.
              </p>
            </div>
            <div className="image-content">
              <div className="gradient-box" />
              <motion.img
                src="/home_icon/child.png"
                alt="Bambino"
                className="child-image"
                initial={{ x: '100%', opacity: 0 }}
                animate={{ x: '0%', opacity: 1 }}
                transition={{ duration: 1 }}
              />
            </div>
          </motion.section>

          {/* Sezione Servizi */}
          <ServicesComponent />
          <BenefitsComponent />
        </main>
      </div>

      <Footer />
    </div>
  );
};

export default HomePage;
