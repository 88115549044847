// src/api/profile/useGetProfile.js
import { useState, useEffect } from 'react';
import apiClient from '../apiClient';

const useGetProfile = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchProfile = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiClient.get('/api/accounts/profile/');
      setProfile(response.data);
    } catch (err) {
      setError(err.response?.data?.error || 'Errore nel recupero del profilo');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return { profile, loading, error, refetch: fetchProfile };
};

export default useGetProfile;
