// CheckoutSuccess.js

import React, { useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

const CheckoutSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Estrae l'ID della sessione dalla URL
  const sessionId = new URLSearchParams(location.search).get('session_id');

  useEffect(() => {
    // Puoi utilizzare sessionId per fare una chiamata al backend se necessario
    console.log('ID della sessione di checkout:', sessionId);
  }, [sessionId]);

  return (
    <Box
      sx={{
        fontFamily: 'Inter, sans-serif',
        color: '#2B2E34',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2rem',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Grazie per il tuo acquisto!
      </Typography>
      <Typography variant="body1" gutterBottom>
        Il tuo abbonamento è stato attivato con successo.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/')}
        sx={{ marginTop: '1rem' }}
      >
        Torna alla Home
      </Button>
    </Box>
  );
};

export default CheckoutSuccess;
