// ChatMessages.js
import React, { useState } from 'react';
import {
  Box,
  useMediaQuery,
  Typography,
  CircularProgress,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';

import { keyframes } from '@emotion/react';
import { Document, Packer, Paragraph, TextRun, HeadingLevel } from 'docx';
import { saveAs } from 'file-saver';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { marked } from 'marked';
import he from 'he';

// Import pdfmake e html-to-pdfmake
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';

// Import dei nuovi componenti
import MediaDisplay from './MediaDisplay';
import ActionButtons from './ActionButtons';
import MediaTypeDialog from './MediaTypeDialog';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const typing = keyframes`
  0% { opacity: 0.2; }
  20% { opacity: 1; }
  100% { opacity: 0.2; }
`;

const loadingAnimation = keyframes`
  0% { opacity: 0.2; transform: translateY(0); }
  20% { opacity: 1; transform: translateY(-5px); }
  100% { opacity: 0.2; transform: translateY(0); }
`;

const LoadingIndicator = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{
        backgroundColor: '#E57373',
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        margin: '0 5px',
        animation: `${loadingAnimation} 1s infinite`,
        animationDelay: '0s',
      }} />
      <Box sx={{
        backgroundColor: '#E57373',
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        margin: '0 5px',
        animation: `${loadingAnimation} 1s infinite`,
        animationDelay: '0.2s',
      }} />
      <Box sx={{
        backgroundColor: '#E57373',
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        margin: '0 5px',
        animation: `${loadingAnimation} 1s infinite`,
        animationDelay: '0.4s',
      }} />
    </Box>
  </Box>
);

const ChatMessages = ({
  messages,
  loading,
  containerRef,
  chatTitle,
  selectedOption,
  handleMediaSearch,
}) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  // Stato per il dialogo di selezione del tipo di materiale
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
  const [selectedMediaType, setSelectedMediaType] = useState('image');
  const [currentMessage, setCurrentMessage] = useState(null);

  // Funzione per aprire il dialogo di selezione
  const openMediaDialog = (message) => {
    setCurrentMessage(message);
    setMediaDialogOpen(true);
  };

  // Funzione per confermare la selezione e avviare la ricerca
  const handleMediaSearchConfirm = (mediaType) => {
    setMediaDialogOpen(false);
    setSelectedMediaType(mediaType); // Aggiorna lo stato effettivo solo dopo la conferma
    handleMediaSearch(currentMessage, mediaType);
  };

  // Funzione per copiare il testo negli appunti
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Testo copiato negli appunti!');
    }).catch(err => {
      console.error('Errore nella copia: ', err);
    });
  };

  // Funzione per sanitizzare il titolo della chat per l'uso nei nomi dei file
  const sanitizeFileName = (name) => {
    return name.replace(/[^a-z0-9_\-]/gi, '_');
  };

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  // Funzione per scaricare il messaggio come PDF
  const handleDownloadPDF = (markdownText) => {
    const safeTitle = sanitizeFileName(chatTitle || 'chat');

    // Converti Markdown in HTML
    const htmlContent = marked(markdownText);

    // Converti HTML in formato pdfmake
    const pdfContent = htmlToPdfmake(htmlContent);

    const docDefinition = {
      content: pdfContent,
      defaultStyle: {
        font: 'Roboto',
      },
    };

    if (isIOS) {
      pdfMake.createPdf(docDefinition).open();
    } else {
      pdfMake.createPdf(docDefinition).download(`${safeTitle}.pdf`);
    }
  };

  // Funzione per scaricare il messaggio come documento Word (.docx)
  const handleDownload = (markdownText) => {
    const safeTitle = sanitizeFileName(chatTitle || 'chat');

    // Funzione per convertire markdown in elementi docx
    const convertMarkdownToDocx = (markdown) => {
      const docElements = [];

      // Crea lexer
      const lexer = new marked.Lexer();
      const tokens = lexer.lex(markdown);

      tokens.forEach((token) => {
        switch (token.type) {
          case 'heading':
            docElements.push(
              new Paragraph({
                text: he.decode(token.text),
                heading: HeadingLevel[`HEADING_${token.depth}`],
                spacing: { after: 200 - (token.depth * 20) },
              })
            );
            break;
          case 'paragraph':
            const runs = [];
            const inlineTokens = lexer.inlineTokens(token.text);

            inlineTokens.forEach((inlineToken) => {
              if (!inlineToken.type) {
                console.error('Token con tipo undefined:', inlineToken);
                return;
              }
              const decodedText = he.decode(inlineToken.text || '');
              switch (inlineToken.type) {
                case 'text':
                  runs.push(new TextRun({ text: decodedText }));
                  break;
                case 'strong':
                  runs.push(new TextRun({ text: decodedText, bold: true }));
                  break;
                case 'em':
                  runs.push(new TextRun({ text: decodedText, italics: true }));
                  break;
                case 'codespan':
                  runs.push(new TextRun({ text: decodedText, font: 'Courier New' }));
                  break;
                default:
                  runs.push(new TextRun({ text: he.decode(inlineToken.raw || '') }));
                  break;
              }
            });

            docElements.push(
              new Paragraph({
                children: runs,
                spacing: { after: 120 },
              })
            );
            break;
          case 'list':
            token.items.forEach((item) => {
              const itemRuns = [];
              const itemInlineTokens = lexer.inlineTokens(item.text);

              itemInlineTokens.forEach((inlineToken) => {
                if (!inlineToken.type) {
                  console.error('Token con tipo undefined:', inlineToken);
                  return;
                }
                const decodedText = he.decode(inlineToken.text || '');
                switch (inlineToken.type) {
                  case 'text':
                    itemRuns.push(new TextRun({ text: decodedText }));
                    break;
                  case 'strong':
                    itemRuns.push(new TextRun({ text: decodedText, bold: true }));
                    break;
                  case 'em':
                    itemRuns.push(new TextRun({ text: decodedText, italics: true }));
                    break;
                  case 'codespan':
                    itemRuns.push(new TextRun({ text: decodedText, font: 'Courier New' }));
                    break;
                  default:
                    itemRuns.push(new TextRun({ text: he.decode(inlineToken.raw || '') }));
                    break;
                }
              });

              docElements.push(
                new Paragraph({
                  children: itemRuns,
                  bullet: {
                    level: item.depth || 0,
                  },
                  spacing: { after: 120 },
                })
              );
            });
            break;
          case 'space':
            docElements.push(new Paragraph({ text: '', spacing: { after: 120 } }));
            break;
          default:
            break;
        }
      });

      return docElements;
    };

    // Crea il documento
    const doc = new Document({
      styles: {
        paragraphStyles: [
          {
            id: 'default',
            name: 'Default Style',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              font: 'Calibri',
              size: 22,
            },
          },
        ],
      },
      sections: [
        {
          properties: {},
          children: convertMarkdownToDocx(markdownText),
        },
      ],
    });

    // Genera e salva il blob del documento
    if (isIOS) {
      Packer.toBase64String(doc)
        .then((base64String) => {
          const url = 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,' + base64String;
          const link = document.createElement('a');
          link.href = url;
          link.download = `${safeTitle}.docx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {
          console.error('Errore nella generazione del documento: ', err);
        });
    } else {
      Packer.toBlob(doc)
        .then((blob) => {
          saveAs(blob, `${safeTitle}.docx`);
        })
        .catch((err) => {
          console.error('Errore nella generazione del documento: ', err);
        });
    }
  };

  // Funzione per ottenere l'icona del file basata sul tipo
  const getAttachmentIcon = (attachment) => {
    const fileExtension = attachment.file_name.split('.').pop().toLowerCase();
    switch (fileExtension) {
      case 'pdf':
        return <PictureAsPdfIcon color="error" />;
      case 'doc':
      case 'docx':
        return <InsertDriveFileIcon style={{ color: '#3F51B5' }} />;
      case 'txt':
        return <InsertDriveFileIcon style={{ color: '#4CAF50' }} />;
      default:
        return <AttachFileIcon />;
    }
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        flexGrow: 1,
        overflowY: 'auto',
        padding: isMobile ? '1rem' : '2rem',
        overflowWrap: 'break-word',
        position: 'relative',
        marginTop: isMobile ? '0' : '-1.5rem',
      }}
    >
      {/* Dialogo di selezione del tipo di materiale */}
      <MediaTypeDialog
        open={mediaDialogOpen}
        onClose={() => setMediaDialogOpen(false)}
        selectedMediaType={selectedMediaType}
        onConfirm={handleMediaSearchConfirm}
      />

      {/* Contenuto principale */}
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          {messages.map((message, index) => (
            <Box
              key={index}
              sx={{
                marginBottom: isMobile ? '1rem' : '2.5rem',
                position: 'relative',
                marginTop: index === 0 ? '2rem' : '0',
              }}
            >
              {message.sender === 'assistant' && message.isTyping ? (
                // Indicatore di digitazione
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'row',
                    textAlign: 'left',
                  }}
                >
                  <Box
                    sx={{
                      width: isMobile ? '2rem' : '2.8125rem',
                      height: isMobile ? '2rem' : '2.8125rem',
                      margin: '0 0.625rem 0 0',
                      backgroundColor: '#DF4634',
                      borderRadius: '1.875rem',
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0.625rem 0.9375rem',
                      borderRadius: '1.875rem',
                      boxShadow: '0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
                      backgroundColor: '#ffffff',
                      maxWidth: '83.5%',
                      fontFamily: 'Inter, sans-serif',
                      fontWeight: 400,
                      fontSize: '0.875rem',
                      lineHeight: '1rem',
                      padding: isMobile ? '1rem' : '1.5rem',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: '#DF4634',
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          margin: '0 2px',
                          animation: `${typing} 1s infinite`,
                          animationDelay: '0s',
                        }}
                      />
                      <Box
                        sx={{
                          backgroundColor: '#DF4634',
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          margin: '0 2px',
                          animation: `${typing} 1s infinite`,
                          animationDelay: '0.2s',
                        }}
                      />
                      <Box
                        sx={{
                          backgroundColor: '#DF4634',
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          margin: '0 2px',
                          animation: `${typing} 1s infinite`,
                          animationDelay: '0.4s',
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              ) : (
                <>
                  {/* Messaggio utente o assistente */}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      flexDirection: message.sender === 'user' ? 'row-reverse' : 'row',
                      textAlign: message.sender === 'user' ? 'left' : 'left',
                    }}
                  >
                    {message.sender === 'assistant' && (selectedOption === 'montessori' || selectedOption === 'malaguzzi') ? (
                      <Box
                        component="img"
                        src={`/people/${selectedOption}.png`}
                        alt={selectedOption === 'montessori' ? 'Maria Montessori' : 'Loris Malaguzzi'}
                        sx={{
                          width: isMobile ? '2rem' : '2.8125rem',
                          height: isMobile ? '2rem' : '2.8125rem',
                          margin: isMobile ? '0 0.5rem 0 0' : '0 0.625rem 0 0',
                          borderRadius: '50%',
                        }}
                      />
                    ) : (
                      <Box
                        component="img"
                        src={message.sender === 'user' ? '/menu_icon/profile.png' : '/chat_icon/minerva_robot.png'}
                        sx={{
                          width: isMobile ? '2rem' : '2.8125rem',
                          height: isMobile ? '2rem' : '2.8125rem',
                          margin: message.sender === 'user'
                            ? isMobile ? '0 0 0 0.5rem' : '0 0 0 0.625rem'
                            : isMobile ? '0 0.5rem 0 0' : '0 0.625rem 0 0',
                          backgroundColor: message.sender === 'user' ? '#000000' : '#DF4634',
                          borderRadius: '1.875rem',
                        }}
                      />
                    )}
                    <Box
                      sx={{
                        position: 'relative',
                        padding: isMobile ? '1rem' : '2rem',
                        borderRadius: '1.875rem',
                        boxShadow: '0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
                        whiteSpace: 'pre-wrap',
                        backgroundColor: '#ffffff',
                        color: '#000000',
                        maxWidth: message.sender === 'user' ? '70%' : '83.5%',
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 400,
                        fontSize: isMobile ? '0.85rem' : '0.875rem',
                        lineHeight: '1rem',
                      }}
                    >
                      <ReactMarkdown
                        children={message.text}
                      />
                      {message.attachments && message.attachments.length > 0 && (
                        <Box sx={{ marginTop: '0.5rem' }}>
                          {message.attachments.map((attachment, idx) => (
                            <Box
                              key={idx}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '0.5rem',
                                backgroundColor: '#f5f5f5',
                                padding: '0.5rem 1rem',
                                borderRadius: '0.5rem',
                              }}
                            >
                              {getAttachmentIcon(attachment)}
                              <Typography variant="body2" sx={{ marginLeft: '0.5rem', flexGrow: 1 }}>
                                {attachment.file_name}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      )}
                    </Box>
                  </Box>

                  {/* Pulsanti di azione per i messaggi dell'assistente */}
                  {message.sender === 'assistant' && !message.isTyping && !message.isInitial && (
                    <ActionButtons
                      messageText={message.text}
                      onCopy={handleCopy}
                      onDownloadWord={handleDownload}
                      onDownloadPDF={handleDownloadPDF}
                      onSearchMaterial={() => openMediaDialog(message)}
                      isMobile={isMobile}
                      disableSearch={message.imagesLoading || message.images}
                    />
                  )}

                  {/* Mostra caricamento di immagini/video/PDF */}
                  {message.imagesLoading && (
                    <Box sx={{ marginTop: '1rem', textAlign: 'center' }}>
                      <CircularProgress />
                      <Typography variant="body2" color="textSecondary">
                        Ricerca in corso...
                      </Typography>
                    </Box>
                  )}

                  {/* Visualizza i media disponibili utilizzando il componente MediaDisplay */}
                  <MediaDisplay media={message.media} selectedMediaType={selectedMediaType} />
                </>
              )}
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};

export default ChatMessages;
