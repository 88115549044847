// src/components/ResetPassword.js
import React, { useState } from 'react';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import { Box, Button, TextField, Typography, Alert, CircularProgress } from '@mui/material';

const baseUrl = process.env.REACT_APP_BASE_URL;

const ResetPassword = () => {
  const { token } = useParams(); // Ottiene il token dall'URL se presente
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(''); // Stato per gestire l'errore dell'email
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Stato per gestire il caricamento

  // Funzione di validazione dell'email
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // Funzione per gestire la richiesta di reset password
  const handleResetRequest = async (e) => {
    e.preventDefault();

    // Validazione dell'email
    if (!validateEmail(email)) {
      setEmailError('Per favore, inserisci un indirizzo email valido.');
      return;
    }

    setIsLoading(true); // Inizia il caricamento

    try {
      const response = await fetch(`${baseUrl}/api/accounts/password-reset/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Qualcosa è andato storto');
      }

      setMessage('Se un account con questa email esiste, abbiamo inviato le istruzioni per reimpostare la password.');
      setError(null);
      setFormSubmitted(true);
    } catch (error) {
      console.error('Error:', error);
      setError(error.message);
      setMessage(null);
    } finally {
      setIsLoading(false); // Termina il caricamento
    }
  };

  // Funzione per gestire la conferma del reset password
  const handleResetConfirm = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError('Le password non corrispondono');
      return;
    }

    setIsLoading(true); // Inizia il caricamento

    try {
      const response = await fetch(`${baseUrl}/api/accounts/password-reset/${token}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ new_password: newPassword, confirm_password: confirmPassword }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Qualcosa è andato storto');
      }

      setMessage('Password reimpostata con successo! Ora puoi effettuare il login con la nuova password.');
      setError(null);
      setFormSubmitted(true);
    } catch (error) {
      console.error('Error:', error);
      setError(error.message);
      setMessage(null);
    } finally {
      setIsLoading(false); // Termina il caricamento
    }
  };

  const handleDone = () => {
    navigate('/login'); // Reindirizza alla pagina di login
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#F3F6FB',
        position: 'relative',
        padding: '20px',
        fontFamily: 'Inter, sans-serif',
      }}
    >
      <RouterLink to="/">
        <img
          src="/logo/logogrey.svg"
          alt="Logo"
          style={{
            width: '83px',
            height: '76px',
            marginBottom: '-20px',
          }}
        />
      </RouterLink>

      {/* Se il form è stato inviato con successo, mostra il messaggio di conferma */}
      {formSubmitted ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            width: 320,
            padding: 2,
          }}
        >
          <Typography variant="h5" component="h1" gutterBottom sx={{ fontWeight: 600 }}>
            {message}
          </Typography>
          <Button
            onClick={handleDone}
            variant="contained"
            fullWidth
            sx={{
              width: 320,
              height: 50,
              backgroundColor: '#DF4634',
              borderRadius: '30px',
              textTransform: 'none',
              fontSize: '15px',
              lineHeight: '27px',
              color: '#FFFFFF',
              marginTop: '16px',
              '&:hover': {
                backgroundColor: '#DF4634',
              },
            }}
          >
            Fatto
          </Button>
        </Box>
      ) : (
        <Box
          component="form"
          onSubmit={token ? handleResetConfirm : handleResetRequest}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 320,
            padding: 2,
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 600 }}>
            {token ? 'Reimposta la tua password' : 'Recupera la password'}
          </Typography>
          {error && (
            <Alert severity="error" sx={{ width: '100%', marginBottom: 2 }}>
              {error}
            </Alert>
          )}
          {message && (
            <Alert severity="success" sx={{ width: '100%', marginBottom: 2 }}>
              {message}
            </Alert>
          )}
          {!token ? (
            <>
              <TextField
                label="Indirizzo email"
                variant="outlined"
                fullWidth
                margin="normal"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  // Rimuovi l'errore quando l'utente modifica l'input
                  if (emailError) setEmailError('');
                }}
                onBlur={() => {
                  if (email && !validateEmail(email)) {
                    setEmailError('Per favore, inserisci un indirizzo email valido.');
                  }
                }}
                error={Boolean(emailError)}
                helperText={emailError}
                sx={{
                  width: '320px',
                  height: '64px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '30px',
                    '& fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&:hover fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#DF4634',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: '#DF4634',
                    fontFamily: 'Inter, sans-serif',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#DF4634',
                  },
                }}
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={isLoading} // Disabilita il bottone durante il caricamento
                sx={{
                  width: 320,
                  height: 50,
                  backgroundColor: '#DF4634',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderRadius: '30px',
                  textTransform: 'none',
                  fontSize: '15px',
                  lineHeight: '27px',
                  color: '#FFFFFF',
                  marginTop: '16px',
                  '&:hover': {
                    backgroundColor: '#DF4634',
                  },
                }}
              >
                {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Invia'}
              </Button>
            </>
          ) : (
            <>
              <TextField
                label="Nuova Password"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                sx={{
                  width: '320px',
                  height: '64px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '30px',
                    '& fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&:hover fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#DF4634',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: '#DF4634',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#DF4634',
                  },
                }}
              />
              <TextField
                label="Conferma Nuova Password"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                sx={{
                  width: '320px',
                  height: '64px',
                  marginTop: '8px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '30px',
                    '& fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&:hover fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#DF4634',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: '#DF4634',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#DF4634',
                  },
                }}
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={isLoading} // Disabilita il bottone durante il caricamento
                sx={{
                  width: 320,
                  height: 50,
                  backgroundColor: '#DF4634',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderRadius: '30px',
                  textTransform: 'none',
                  fontSize: '15px',
                  lineHeight: '27px',
                  color: '#FFFFFF',
                  marginTop: '16px',
                  '&:hover': {
                    backgroundColor: '#DF4634',
                  },
                }}
              >
                {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Reimposta Password'}
              </Button>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ResetPassword;
