import React, { useRef, useState } from 'react';
import { 
  Box, 
  Typography, 
  CircularProgress, 
  IconButton,
  Snackbar,
  Alert
} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useNavigate } from 'react-router-dom';
import './styles/ProfileDocuments.css';

// Importa gli hook personalizzati
import useGetDocuments from '../../api/documents/useGetDocuments';
import useCreateDocument from '../../api/documents/useCreateDocument';
import useRemoveDocument from '../../api/documents/useRemoveDocument';

const ProfileDocuments = () => {
  const fileInputRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  
  const navigate = useNavigate();

  // Definizione delle costanti di validazione
  const MAX_SIZE = 5 * 1024 * 1024; // 5MB
  const ALLOWED_TYPES = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain'
  ];

  // Utilizza gli hook personalizzati
  const { documents, loading: documentsLoading, error: documentsError, refetch: refetchDocuments } = useGetDocuments();
  const { createDocument, loading: createLoading, error: createError } = useCreateDocument();
  const { removeDocument, loading: removeLoading, error: removeError } = useRemoveDocument();

  const handleFileUpload = async (file) => {
    // Validazione preliminare del file
    if (file.size > MAX_SIZE) {
      setSnackbar({ open: true, message: 'Il file è troppo grande (max 5MB).', severity: 'error' });
      return;
    }
    if (!ALLOWED_TYPES.includes(file.type)) {
      setSnackbar({ open: true, message: 'Tipo di file non supportato.', severity: 'error' });
      return;
    }

    setUploading(true);
    try {
      // Rimuovi l'estensione del file dal nome
      const documentName = file.name.replace(/\.[^/.]+$/, "");
      await createDocument(documentName, file);
      refetchDocuments();
      setSnackbar({ open: true, message: 'Documento caricato con successo!', severity: 'success' });
    } catch (error) {
      const errorMessage = error.message || 'Si è verificato un errore durante il caricamento del documento.';
      setSnackbar({ open: true, message: `Errore: ${errorMessage}`, severity: 'error' });
    } finally {
      setUploading(false);
    }
  };

  const handleAddDocumentClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  const handleDocumentClick = (fileUrl) => {
    window.open(fileUrl, '_blank');
  };

  const handleRemoveDocument = async (documentId) => {
    if (window.confirm('Sei sicuro di voler eliminare questo documento?')) {
      try {
        await removeDocument(documentId);
        refetchDocuments();
        setSnackbar({ open: true, message: 'Documento eliminato con successo!', severity: 'success' });
      } catch (error) {
        setSnackbar({ open: true, message: `Errore: ${error.message}`, severity: 'error' });
      }
    }
  };

  return (
    <Box className="profile-documents">
      <Typography variant="h4" className="documents-title" gutterBottom>
        Documenti
      </Typography>
      
      {(documentsError || createError || removeError) && (
        <Typography color="error">
          {documentsError || createError || removeError}
        </Typography>
      )}

      {documentsLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <CircularProgress />
        </Box>
      ) : (
        <Box className="categories-container">
          <Box className="categories-grid">
            
            {/* Div per aggiungere nuovi documenti - posizionato come primo */}
            <Box className="category-item">
              <Typography variant="body2" className="category-title">
                Aggiungi Documento
              </Typography>
              <Box
                className="document-category add-document"
                onClick={handleAddDocumentClick}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                sx={{
                  position: 'relative',
                  '&:hover .remove-btn': {
                    opacity: 1, // Anche se qui non c'è remove-btn, mantenuto per coerenza
                  },
                }}
              >
                {uploading ? (
                  <CircularProgress size={24} />
                ) : (
                  <img src="/profile_icon/add_file.png" alt="Aggiungi Documento" className="add-file-icon" />
                )}
                <input
                  type="file"
                  accept=".pdf,.doc,.docx,.txt"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
              </Box>
            </Box>

            {/* Documenti caricati */}
            {documents.map((doc) => (
              <Box className="category-item" key={doc.id}>
                <Typography variant="body2" className="category-title">
                  {doc.document_name || 'Documento'}
                </Typography>
                <Box
                  className="document-category"
                  onClick={() => handleDocumentClick(doc.file_url)}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  sx={{
                    position: 'relative',
                    '&:hover .remove-btn': {
                      opacity: 1,
                    },
                  }}
                >
                  <PictureAsPdfIcon style={{ fontSize: 48, color: '#ffffff' }} />
                  
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveDocument(doc.id);
                    }}
                    className="remove-btn"
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      color: 'white',
                      opacity: 0,
                      transition: 'opacity 0.3s',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                      },
                    }}
                  >
                    <img src="/chat_icon/remove.png" alt="Rimuovi Documento" style={{ width: '20px', height: '20px' }} />
                  </IconButton>
                </Box>
              </Box>
            ))}

          </Box>
        </Box>
      )}

      {/* Snackbar per le notifiche */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProfileDocuments;
