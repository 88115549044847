// src/api/documents/teachingArchiveApi.js

import apiClient from '../apiClient';

// Funzione per ottenere la lista dei documenti
export const getTeachingDocuments = async () => {
  try {
    const response = await apiClient.get('/api/teachingarchive/documents/');
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Funzione per caricare un nuovo documento (se necessario)
export const uploadTeachingDocument = async (documentName, file) => {
  try {
    const formData = new FormData();
    formData.append('document_name', documentName);
    formData.append('file', file);

    const response = await apiClient.post('/api/teachingarchive/documents/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

// 🆕 Nuova funzione per ottenere un documento per titolo
export const getTeachingDocumentByName = async (documentName) => {
  try {
    const encodedName = encodeURIComponent(documentName);
    const response = await apiClient.get(`/api/teachingarchive/documents/${encodedName}/`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
