// AgentSwitch.js
import React from 'react';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';

const AgentSwitch = ({
  selectedOption,
  // Rimuovi le seguenti props poiché non sono più necessarie
  // didatticaOpen,
  // setDidatticaOpen,
  // handleOptionChange,
}) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Box sx={{ display: 'inline-block' }}>
      <Button
        variant="contained"
        // Rimuovi l'onClick se non necessario o modifica per un'altra azione
        // onClick={() => setDidatticaOpen(!didatticaOpen)}
        sx={{
          backgroundColor: '#DF4634', // Puoi mantenere un colore fisso o dinamico se necessario
          color: '#FFFFFF',
          borderRadius: '1.875rem',
          width: '7rem',
          height: '2.2rem',
          position: isMobile ? 'relative' : 'fixed',
          left: isMobile ? 'auto' : '20%',
          top: isMobile ? 'auto' : '2.5rem',
          textTransform: 'none',
          fontSize: '0.875rem',
          fontWeight: 500,
          textAlign: 'center',
          zIndex: isMobile ? 0 : 1600,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
          '&:hover': {
            backgroundColor: '#E57373',
          },
        }}
      >
        {/* Mostra l'opzione selezionata con la prima lettera maiuscola */}
        {selectedOption.charAt(0).toUpperCase() + selectedOption.slice(1)}
      </Button>
    </Box>
  );
};

export default AgentSwitch;
