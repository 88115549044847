// src/api/documents/useCreateDocument.js
import { useState } from 'react';
import apiClient from '../apiClient';

const useCreateDocument = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Crea o aggiorna un documento utente con un nome personalizzato.
   *
   * @param {string} documentName - Il nome personalizzato del documento.
   * @param {File} file - Il file da caricare.
   * @returns {object} - I dati del documento creato o aggiornato.
   */
  const createDocument = async (documentName, file) => {
    setLoading(true);
    setError(null);
    try {
      // Assicurati che documentName includa l'estensione del file
      const sanitizedDocumentName = documentName.trim();

      // Verifica se documentName include l'estensione, altrimenti aggiungila
      const hasExtension = /\.[0-9a-z]+$/i.test(sanitizedDocumentName);
      let finalDocumentName = sanitizedDocumentName;

      if (!hasExtension) {
        const extensionMatch = file.name.match(/\.[0-9a-z]+$/i);
        const extension = extensionMatch ? extensionMatch[0] : '';
        finalDocumentName = `${sanitizedDocumentName}${extension}`;
      }

      const formData = new FormData();
      formData.append('document_name', finalDocumentName);
      formData.append('file', file);

      const response = await apiClient.post('/api/accounts/documents/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data;
    } catch (err) {
      const errorMessage = err.response?.data?.document_name?.[0] || 'Errore nella creazione del documento';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return { createDocument, loading, error };
};

export default useCreateDocument;
