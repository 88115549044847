// src/components/Signup.js
import React, { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import VideoComponent from './VideoComponent';
import {
  Box,
  Button,
  TextField,
  Typography,
  Alert,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  Link,
} from '@mui/material';
import useRegister from '../api/auth/useRegister';

// Definizione delle materie
const subjects = [
  'Sostegno',
  'Italiano',
  'Storia',
  'Geografia',
  'Matematica',
  'Scienze',
  'Tecnologia',
  'Musica',
  'Arte e Immagine',
  'Inglese',
  'Educazione Civica',
  'Educazione Fisica',
  'Religione Cattolica',
];

// Definizione delle scuole
const schools = ['Infanzia', 'Primaria', 'Secondaria di primo grado'];

// Definizione delle classi in base alla scuola
const classesBySchool = {
  Infanzia: ['Prima', 'Seconda', 'Terza'],
  Primaria: ['Prima', 'Seconda', 'Terza', 'Quarta', 'Quinta'],
  'Secondaria di primo grado': ['Prima', 'Seconda', 'Terza'],
};

const Signup = () => {
  // Stati per gestire i campi del form
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [teachingSubject, setTeachingSubject] = useState([]); // Modificato per array
  const [school, setSchool] = useState('');
  const [currentClassType, setCurrentClassType] = useState([]); // Modificato per array
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false); // Stato per nascondere il form

  // Aggiungi lo stato per la checkbox della Privacy Policy
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const [privacyPolicyError, setPrivacyPolicyError] = useState('');

  const navigate = useNavigate();
  const { register, loading, error } = useRegister(); // Destruttura le proprietà dal hook

  const [success, setSuccess] = useState(false); // Stato per messaggio di successo
  const [passwordError, setPasswordError] = useState(''); // Stato per errore di password

  // Stati per gestire l'apertura delle dropdown
  const [openTeachingSubjectMenu, setOpenTeachingSubjectMenu] = useState(false);
  const [openClassTypeMenu, setOpenClassTypeMenu] = useState(false);

  // Definizione delle proprietà del menu per personalizzare gli stili
  const menuProps = {
    PaperProps: {
      sx: {
        // Stili per gli elementi selezionati
        '& .MuiMenuItem-root.Mui-selected': {
          backgroundColor: '#DF4634', // Rosso
          color: '#FFFFFF', // Testo bianco
          '&:hover': {
            backgroundColor: '#DF4634', // Mantiene il rosso al hover
          },
        },
        // Stili per gli elementi al passaggio del mouse
        '& .MuiMenuItem-root:hover': {
          backgroundColor: '#DF4634', // Rosso al hover
          color: '#FFFFFF', // Testo bianco al hover
        },
        // Stili per l'area del pulsante "Fatto"
        '& .fatto-button': {
          display: 'flex',
          justifyContent: 'center',
          padding: '10px',
        },
      },
    },
    // Evita che il menu si chiuda automaticamente quando si clicca su un elemento
    disableScrollLock: true,
  };

  // Funzione per gestire il passaggio alla seconda parte del form
  const handleContinue = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setSuccess(false); // Resetta lo stato di successo
      setPasswordError('Le password non coincidono'); // Imposta l'errore di password
      return;
    }
    if (!privacyPolicyAccepted) {
      setSuccess(false);
      setPrivacyPolicyError('Devi accettare la Privacy Policy per procedere.');
      return;
    }
    setShowAdditionalFields(true);
    setPasswordError(''); // Ripulisci eventuali errori precedenti
    setPrivacyPolicyError('');
    setSuccess(false); // Resetta lo stato di successo
  };

  // Funzione per gestire l'invio del form di registrazione
  const handleSignup = async (e) => {
    e.preventDefault();

    // Conversione degli array in stringhe separate da virgola
    const teachingSubjectsString = teachingSubject.join(', ');
    const classTypesString = currentClassType.join(', ');

    const userData = {
      email,
      first_name: firstName,
      last_name: lastName,
      password,
      teaching_subject: teachingSubjectsString, // Convertito in stringa
      school,
      current_class_type: classTypesString, // Convertito in stringa
    };

    try {
      const data = await register(userData); // Utilizza il metodo register del hook
      console.log('Success:', data);

      setSuccess(true);
      setFormSubmitted(true); // Nasconde il form e mostra il messaggio
    } catch (error) {
      console.error('Error:', error);
      setSuccess(false);
      // L'errore è già gestito dallo stato del hook
    }
  };

  // Funzione per gestire il reindirizzamento dopo la registrazione
  const handleDone = () => {
    navigate('/'); // Reindirizza alla homepage
  };

  // Funzione per gestire il ritorno alla prima parte del form
  const handleBack = () => {
    setShowAdditionalFields(false);
    setSuccess(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: '#F3F6FB',
        position: 'relative',
        padding: '20px',
        fontFamily: 'Inter, sans-serif',
      }}
    >
      <RouterLink to="/">
        <img
          src="/logo/logogrey.svg"
          alt="Logo"
          style={{
            width: '83px',
            height: '76px',
            marginTop: '-20px',
            marginBottom: '-20px',
          }}
        />
      </RouterLink>

      {/* Se il form è stato inviato con successo, mostra il messaggio di conferma e il video */}
      {formSubmitted ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            width: '90%',
            padding: 2,
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 600 }}>
            Controlla la tua email (Anche in SPAM) e conferma l'attivazione!
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            Puoi attivare l'account cliccando sul link di verifica.
          </Typography>

          {/* Inserisci il VideoComponent qui */}
          <VideoComponent videoSrc="/video/activate_account.mp4" className="signup-video" />
        </Box>
      ) : (
        <Box
          component="form"
          onSubmit={showAdditionalFields ? handleSignup : handleContinue}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 320,
            padding: 2,
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 600 }}>
            Crea un account
          </Typography>

          {/* Mostra gli errori del server */}
          {error && (
            <Alert severity="error" sx={{ width: '100%', marginBottom: 2 }}>
              {error}
            </Alert>
          )}

          {/* Mostra l'errore di password se presente */}
          {passwordError && (
            <Alert severity="error" sx={{ width: '100%', marginBottom: 2 }}>
              {passwordError}
            </Alert>
          )}

          {/* Mostra l'errore della Privacy Policy se presente */}
          {privacyPolicyError && (
            <Alert severity="error" sx={{ width: '100%', marginBottom: 2 }}>
              {privacyPolicyError}
            </Alert>
          )}

          {/* Mostra il messaggio di successo locale */}
          {success && (
            <Alert severity="success" sx={{ width: '100%', marginBottom: 2 }}>
              Registrazione completata! Controlla la tua email.
            </Alert>
          )}

          {/* Prima parte del form: email e password */}
          {!showAdditionalFields && (
            <>
              <TextField
                label="Indirizzo email"
                variant="outlined"
                fullWidth
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase())} // Converti in minuscolo
                sx={{
                  width: '320px',
                  height: '64px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '30px',
                    '& fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&:hover fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#DF4634',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: '#DF4634',
                    fontFamily: 'Inter, sans-serif',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#DF4634',
                  },
                }}
              />
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (passwordError && e.target.value === confirmPassword) {
                    setPasswordError('');
                  }
                }}
                sx={{
                  width: '320px',
                  height: '64px',
                  marginTop: '8px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '30px',
                    '& fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&:hover fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#DF4634',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: '#DF4634',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#DF4634',
                  },
                }}
              />
              <TextField
                label="Conferma Password"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  if (passwordError && password === e.target.value) {
                    setPasswordError('');
                  }
                }}
                sx={{
                  width: '320px',
                  height: '64px',
                  marginTop: '8px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '30px',
                    '& fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&:hover fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#DF4634',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: '#DF4634',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#DF4634',
                  },
                }}
              />

              {/* Checkbox per la Privacy Policy */}
              <FormControl
                required
                error={Boolean(privacyPolicyError)}
                sx={{ marginTop: 2 }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={privacyPolicyAccepted}
                      onChange={(e) => setPrivacyPolicyAccepted(e.target.checked)}
                      name="privacyPolicyAccepted"
                      sx={{
                        color: '#DF4634',
                        '&.Mui-checked': {
                          color: '#DF4634',
                        },
                      }}
                    />
                  }
                  label={
                    <Typography variant="body2" sx={{ color: '#2b2e34' }}>
                      Dichiaro di aver letto e accettato la{' '}
                      <Link
                        href="https://www.iubenda.com/privacy-policy/80002115"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          color: '#DF4634',
                          textDecoration: 'none',
                          '&:hover': { textDecoration: 'underline' },
                        }}
                      >
                        Privacy Policy
                      </Link>
                    </Typography>
                  }
                />
              </FormControl>

              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={loading} // Disabilita il bottone durante il caricamento
                sx={{
                  width: 320,
                  height: 50,
                  backgroundColor: '#DF4634',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderRadius: '30px',
                  textTransform: 'none',
                  fontSize: '15px',
                  lineHeight: '27px',
                  color: '#FFFFFF',
                  marginTop: '16px',
                  '&:hover': {
                    backgroundColor: '#DF4634',
                  },
                }}
              >
                {loading ? 'Continuando...' : 'Continua'}
              </Button>

              {/* Link per accedere se si ha già un account */}
              <Typography variant="body2" sx={{ marginTop: 2 }}>
                Hai già un account?{' '}
                <Link
                  component={RouterLink}
                  to="/login"
                  sx={{
                    color: '#DF4634',
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'underline' },
                  }}
                >
                  Accedi
                </Link>
              </Typography>
            </>
          )}

          {/* Seconda parte del form: informazioni aggiuntive */}
          {showAdditionalFields && (
            <>
              <TextField
                label="Nome"
                variant="outlined"
                fullWidth
                margin="normal"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                sx={{
                  width: '320px',
                  height: '64px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '30px',
                    '& fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&:hover fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#DF4634',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: '#DF4634',
                    fontFamily: 'Inter, sans-serif',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#DF4634',
                  },
                }}
              />
              <TextField
                label="Cognome"
                variant="outlined"
                fullWidth
                margin="normal"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                sx={{
                  width: '320px',
                  height: '64px',
                  marginTop: '8px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '30px',
                    '& fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&:hover fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#DF4634',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: '#DF4634',
                    fontFamily: 'Inter, sans-serif',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#DF4634',
                  },
                }}
              />
              {/* Materia insegnata */}
              <FormControl
                variant="outlined"
                fullWidth
                margin="normal"
                sx={{
                  width: '320px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '30px',
                  },
                }}
              >
                <InputLabel
                  sx={{
                    color: '#DF4634',
                    fontFamily: 'Inter, sans-serif',
                    '&.Mui-focused': {
                      color: '#DF4634',
                    },
                  }}
                >
                  Materia insegnata
                </InputLabel>
                <Select
                  multiple
                  value={teachingSubject}
                  onChange={(e) => setTeachingSubject(e.target.value)}
                  label="Materia insegnata"
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={{
                    ...menuProps,
                    open: openTeachingSubjectMenu,
                    onClose: () => setOpenTeachingSubjectMenu(false),
                  }}
                  open={openTeachingSubjectMenu}
                  onOpen={() => setOpenTeachingSubjectMenu(true)}
                  sx={{
                    '& fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&:hover fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#DF4634',
                    },
                  }}
                >
                  {subjects.map((subject) => (
                    <MenuItem key={subject} value={subject}>
                      {subject}
                    </MenuItem>
                  ))}
                  {/* Pulsante "Fatto" nella dropdown */}
                  <MenuItem disableGutters className="fatto-button">
                    <Button
                      onClick={() => setOpenTeachingSubjectMenu(false)}
                      variant="contained"
                      fullWidth
                      sx={{
                        backgroundColor: '#DF4634',
                        color: '#FFFFFF',
                        textTransform: 'none',
                        '&:hover': {
                          backgroundColor: '#DF4634',
                        },
                      }}
                    >
                      Fatto
                    </Button>
                  </MenuItem>
                </Select>
              </FormControl>
              {/* Scuola */}
              <FormControl
                variant="outlined"
                fullWidth
                margin="normal"
                sx={{
                  width: '320px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '30px',
                  },
                }}
              >
                <InputLabel
                  sx={{
                    color: '#DF4634',
                    fontFamily: 'Inter, sans-serif',
                    '&.Mui-focused': {
                      color: '#DF4634',
                    },
                  }}
                >
                  Scuola
                </InputLabel>
                <Select
                  value={school}
                  onChange={(e) => {
                    setSchool(e.target.value);
                    setCurrentClassType([]); // Reset della classe quando cambia la scuola
                  }}
                  label="Scuola"
                  MenuProps={menuProps}
                  sx={{
                    '& fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&:hover fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#DF4634',
                    },
                  }}
                >
                  {schools.map((schoolOption) => (
                    <MenuItem key={schoolOption} value={schoolOption}>
                      {schoolOption}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* Classe */}
              <FormControl
                variant="outlined"
                fullWidth
                margin="normal"
                sx={{
                  width: '320px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '30px',
                  },
                }}
                disabled={!school} // Disabilita se la scuola non è selezionata
              >
                <InputLabel
                  sx={{
                    color: '#DF4634',
                    fontFamily: 'Inter, sans-serif',
                    '&.Mui-focused': {
                      color: '#DF4634',
                    },
                  }}
                >
                  Classe
                </InputLabel>
                <Select
                  multiple
                  value={currentClassType}
                  onChange={(e) => setCurrentClassType(e.target.value)}
                  label="Classe"
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={{
                    ...menuProps,
                    open: openClassTypeMenu,
                    onClose: () => setOpenClassTypeMenu(false),
                  }}
                  open={openClassTypeMenu}
                  onOpen={() => setOpenClassTypeMenu(true)}
                  sx={{
                    '& fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&:hover fieldset': {
                      borderColor: '#DF4634',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#DF4634',
                    },
                  }}
                >
                  {school &&
                    classesBySchool[school].map((classType) => (
                      <MenuItem key={classType} value={classType}>
                        {classType}
                      </MenuItem>
                    ))}
                  {/* Pulsante "Fatto" nella dropdown */}
                  <MenuItem disableGutters className="fatto-button">
                    <Button
                      onClick={() => setOpenClassTypeMenu(false)}
                      variant="contained"
                      fullWidth
                      sx={{
                        backgroundColor: '#DF4634',
                        color: '#FFFFFF',
                        textTransform: 'none',
                        '&:hover': {
                          backgroundColor: '#DF4634',
                        },
                      }}
                    >
                      Fatto
                    </Button>
                  </MenuItem>
                </Select>
              </FormControl>
              {/* Pulsanti "Indietro" e "Iscriviti" */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginTop: '16px',
                }}
              >
                <Button
                  type="button"
                  variant="outlined"
                  onClick={handleBack}
                  sx={{
                    height: 50,
                    backgroundColor: '#FFFFFF',
                    borderColor: '#DF4634',
                    borderRadius: '30px',
                    textTransform: 'none',
                    fontSize: '15px',
                    lineHeight: '27px',
                    color: '#DF4634',
                    '&:hover': {
                      backgroundColor: '#F3F6FB',
                      borderColor: '#DF4634',
                    },
                  }}
                >
                  Indietro
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={loading} // Disabilita il bottone durante il caricamento
                  sx={{
                    height: 50,
                    backgroundColor: '#DF4634',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    borderRadius: '30px',
                    textTransform: 'none',
                    fontSize: '15px',
                    lineHeight: '27px',
                    color: '#FFFFFF',
                    '&:hover': {
                      backgroundColor: '#DF4634',
                    },
                  }}
                >
                  {loading ? 'Iscrivendosi...' : 'Iscriviti'}
                </Button>
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Signup;
