// MobileSidebar.js
import React from 'react';
import { Box, IconButton, Drawer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SidebarContent from './SidebarContent'; // Contenuto estratto dal sidebar


const MobileSidebar = ({ onClose, ...props }) => {
  return (
    <Drawer
      anchor="left"
      open={true}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '250px',
          padding: '1rem',
          zIndex: (theme) => theme.zIndex.appBar + 2, // Imposta un zIndex elevato per il Drawer
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(var(--vh, 1vh) * 93) ', // Altezza dinamica
        }}
      >
        {/* Header con il titolo e il pulsante di chiusura */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1rem',
            borderBottom: '1px solid #e0e0e0',
          }}
        >
          {/* Puoi aggiungere un titolo o un pulsante di chiusura qui se necessario */}
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Contenuto del sidebar */}
        <SidebarContent {...props} onClose={onClose} /> {/* Passa onClose esplicitamente */}
      </Box>
    </Drawer>
  );
};

export default MobileSidebar;
