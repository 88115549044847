// src/api/subscription/useCustomerPortal.js

import { useState } from 'react';
import apiClient from '../apiClient';

const useCustomerPortal = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [portalUrl, setPortalUrl] = useState(null);

  const openCustomerPortal = async () => {
    setLoading(true);
    setError(null);
    setPortalUrl(null);
    try {
      const response = await apiClient.post('/api/accounts/customer-portal/');
      setPortalUrl(response.data.url);
      window.location.href = response.data.url;  // Reindirizza l'utente al Customer Portal
    } catch (err) {
      setError(err.response?.data?.error || 'Errore nell\'accesso al Customer Portal');
      console.error('Errore:', err);
    } finally {
      setLoading(false);
    }
  };

  return { openCustomerPortal, portalUrl, loading, error };
};

export default useCustomerPortal;
