// src/api/profile/useUpdateProfile.js
import { useState } from 'react';
import apiClient from '../apiClient';

const useUpdateProfile = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const updateProfile = async (profileData) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiClient.put('/api/accounts/profile/update/', profileData);
      setMessage(response.data.message);
      return response.data;
    } catch (err) {
      setError(err.response?.data?.error || 'Errore nell\'aggiornamento del profilo');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { updateProfile, loading, error, message };
};

export default useUpdateProfile;
