// src/hooks/useTeachingArchive.js

import { useState, useEffect } from 'react';
import { getTeachingDocuments, uploadTeachingDocument } from '../api/documents/teachingArchiveApi';

const useTeachingArchive = () => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Funzione per recuperare i documenti
  const fetchDocuments = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await getTeachingDocuments();
      setDocuments(data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  // Funzione per caricare un documento
  const uploadDocument = async (document) => {
    setLoading(true);
    setError(null);
    try {
      await uploadTeachingDocument(document);
      await fetchDocuments(); // Aggiorna la lista dei documenti dopo il caricamento
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  return {
    documents,
    loading,
    error,
    fetchDocuments,
    uploadDocument,
  };
};

export default useTeachingArchive;
