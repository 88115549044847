// src/api/subscription/useDeactivateStripeRenewal.js
import { useState } from 'react';
import apiClient from '../apiClient';

const useDeactivateStripeRenewal = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const deactivateRenewal = async () => {
    setLoading(true);
    setError(null);
    setSuccessMessage(null);
    try {
      const response = await apiClient.post('/api/accounts/deactivate-stripe-renewal/');
      setSuccessMessage(response.data.message);  // Imposta il messaggio di successo
      return response.data.message;
    } catch (err) {
      setError(err.response?.data?.error || 'Errore nella disattivazione del rinnovo su Stripe');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { deactivateRenewal, successMessage, loading, error };
};

export default useDeactivateStripeRenewal;
