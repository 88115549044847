import React from 'react';
import PropTypes from 'prop-types';
import './styles/DeleteAccountModal.css';

const DeleteAccountModal = ({
  isOpen,
  onClose,
  onDelete,
  loading,
  error,
  success,
}) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>Sei sicuro di voler eliminare il tuo account?</h2>
        <p>Questa azione è irreversibile e cancellerà tutti i tuoi dati.</p>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">Account eliminato con successo!</p>}
        <div className="modal-buttons">
          <button
            className="confirm-button"
            onClick={onDelete}
            disabled={loading}
          >
            {loading ? 'Eliminando...' : 'Conferma'}
          </button>
          <button className="cancel-button" onClick={onClose}>
            Annulla
          </button>
        </div>
      </div>
    </div>
  );
};

DeleteAccountModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string,
  success: PropTypes.bool,
};

export default DeleteAccountModal;
