// src/hooks/useTeachingDocument.js

import { useState, useCallback } from 'react';
import { getTeachingDocumentByName } from '../api/documents/teachingArchiveApi';

const useTeachingDocument = () => {
  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDocumentByName = useCallback(async (documentName) => {
    setLoading(true);
    setError(null);
    try {
      const data = await getTeachingDocumentByName(documentName);
      setDocument(data);
    } catch (err) {
      setError(err);
      throw err; // Propaga l'errore al componente
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    document,
    loading,
    error,
    fetchDocumentByName,
  };
};

export default useTeachingDocument;
