// src/components/GoogleButton.js

import React from 'react';
import { Button, Box } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { GoogleLogin } from '@react-oauth/google';
import apiClient from '../api/apiClient'; // Assicurati che apiClient sia configurato correttamente
import { setAccessToken, setRefreshToken } from '../api/authUtils';
import { useNavigate } from 'react-router-dom';

// Definisci uno stile condiviso per i pulsanti social
const socialButtonStyle = {
  width: 320,
  height: 52,
  borderColor: '#C3C8CF',
  borderRadius: '30px',
  textTransform: 'none',
  fontSize: '15px',
  lineHeight: '27px',
  color: '#2E3339',
  marginBottom: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: '#F3F6FB',
  },
};

const GoogleButton = ({ text }) => {
  const navigate = useNavigate();

  const handleSuccess = async (credentialResponse) => {
    try {
      const { credential } = credentialResponse;
      console.log('Google credential (ID token):', credential);

      // Invia il token al backend come 'id_token'
      const response = await apiClient.post('/api/accounts/social/google/', { id_token: credential });
      console.log('Backend response:', response.data);

      const { tokens, needs_additional_info } = response.data;

      // Salva i token nel localStorage
      setAccessToken(tokens.access);
      setRefreshToken(tokens.refresh);
      console.log('Tokens saved:', tokens);

      if (needs_additional_info) {
        navigate('/complete-profile');
      } else {
        navigate('/');
      }
    } catch (error) {
      console.error('Errore durante il login con Google:', error);
      alert('Login con Google fallito. Riprova.');
    }
  };

  const handleError = () => {
    console.error('Login con Google fallito.');
    alert('Login con Google fallito. Riprova.');
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 2 }}>
      {/* Google Login Button */}
      <GoogleLogin
        onSuccess={handleSuccess}
        onError={handleError}
        useOneTap
        render={(renderProps) => (
          <Button
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            variant="outlined"
            fullWidth
            sx={socialButtonStyle}
            startIcon={<GoogleIcon />}
          >
            {text || 'Continua con Google'}
          </Button>
        )}
      />
    </Box>
  );
};

export default GoogleButton;
