// src/api/auth/usePasswordChange.js
import { useState } from 'react';
import apiClient from '../apiClient';

const usePasswordChange = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const changePassword = async (oldPassword, newPassword, confirmPassword) => {
    setLoading(true);
    setError(null);
    setMessage(null);
    try {
      const response = await apiClient.post('/api/accounts/password-change/', {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      });

      setMessage(response.data.message || 'Password cambiata con successo.');
      return response.data;
    } catch (err) {
      // Gestisci l'errore in base alla risposta del backend
      setError(
        err.response?.data?.error ||
          err.response?.data?.message ||
          'Errore nel cambio della password'
      );
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { changePassword, loading, error, message };
};

export default usePasswordChange;
