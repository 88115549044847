// src/api/auth/useLogin.js
import { useState } from 'react';
import apiClient from '../apiClient';

const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const login = async (credentials) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiClient.post('/api/token/', credentials);
      const { access, refresh } = response.data;
      localStorage.setItem('access', access); // Allineato con il componente
      localStorage.setItem('refresh', refresh); // Allineato con il componente
      return response.data;
    } catch (err) {
      setError(err.response?.data?.detail || 'Errore nel login');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { login, loading, error };
};

export default useLogin;
