// src/components/Profile/ProfileDetails.js
import React from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import './styles/ProfileDetails.css';

const ProfilePhoto = ({ isEditing }) => (
  <div className={`profile-photo ${isEditing ? 'hidden' : ''}`}>
    <div className="black-circle">
    <img src="/menu_icon/profile.png" alt="Profilo" className="profile-image" />
    </div>
  </div>
);

const InfoItem = ({ icon, label, value, name, isEditing, handleInputChange }) => {
  return isEditing ? (
    <Box className="info-box-edit">
      <TextField
        label={label}
        name={name}
        value={value || ''}
        onChange={handleInputChange}
        fullWidth
        className="edit-textfield"
        variant="outlined"
      />
    </Box>
  ) : (
    <Box className="info-box">
      <Typography className="info-text">{value}</Typography>
      <img src={icon} alt={label} className="icon-image" />
    </Box>
  );
};


const ProfileDetails = ({
  profileData,
  isEditing,
  updatedProfile,
  handleInputChange,
  handleSaveProfile,
  setIsEditing,
}) => {
  return (
    <Box className="profile-box">
      <img
        src="/profile_icon/modify.png"
        alt="Modifica profilo"
        className="modify-icon"
        onClick={() => setIsEditing(!isEditing)}
      />

      <Box className="header-section">
        <Typography className="title">
          {profileData?.first_name} {profileData?.last_name}
        </Typography>
        {!isEditing && <ProfilePhoto isEditing={isEditing} />}
      </Box>

      {isEditing && (
        <Box className="edit-section">
          <TextField
            label="Nome"
            name="first_name"
            value={updatedProfile.first_name || ''}
            onChange={handleInputChange}
            fullWidth
            className="edit-textfield"
            variant="outlined"
          />
          <TextField
            label="Cognome"
            name="last_name"
            value={updatedProfile.last_name || ''}
            onChange={handleInputChange}
            fullWidth
            className="edit-textfield"
            variant="outlined"
          />
        </Box>
      )}

      <Box className="info-section">
        <InfoItem
          icon="/profile_icon//school.png"
          label="Scuola"
          name="school"
          value={isEditing ? updatedProfile.school : profileData?.school}
          isEditing={isEditing}
          handleInputChange={handleInputChange}
        />
        <InfoItem
          icon="/profile_icon//class.png"
          label="Classi"
          name="current_class_type"
          value={isEditing ? updatedProfile.current_class_type : profileData?.current_class_type}
          isEditing={isEditing}
          handleInputChange={handleInputChange}
        />
        <InfoItem
          icon="/profile_icon//cover.png"
          label="Materie"
          name="teaching_subject"
          value={isEditing ? updatedProfile.teaching_subject : profileData?.teaching_subject}
          isEditing={isEditing}
          handleInputChange={handleInputChange}
        />
      </Box>

      {isEditing && (
        <Button
          variant="contained"
          onClick={handleSaveProfile}
          className="save-button"
        >
          Salva
        </Button>
      )}
    </Box>
  );
};

export default ProfileDetails;
