// src/api/subscription/useProcessVoucher.js
import { useState } from 'react';
import apiClient from '../apiClient';

const useProcessVoucher = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const processVoucher = async (codiceVoucher) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiClient.post('/api/accounts/process-voucher/', {
        codiceVoucher,
      });
      // Puoi gestire la risposta come preferisci, ad esempio:
      // const data = response.data;
      // return data;

      // In questo caso, ritorniamo il messaggio di successo
      return response.data.message;
    } catch (err) {
      setError(err.response?.data?.error || 'Errore durante la elaborazione del voucher');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { processVoucher, loading, error };
};

export default useProcessVoucher;
