// Chat.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, useMediaQuery, Button } from '@mui/material';
import Sidebar from './Sidebar';
import ChatMessages from './ChatMessages';
import MessageInput from './MessageInput';
import AgentSwitch from './agentSwitch';
import MobileHeader from './MobileHeader';
import MobileSidebar from './MobileSidebar';
import AgentStart from './AgentStart';
import ReachedLimit from './ReachedLimit';
import AgentStartMobile from './AgentStartMobile';
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
  removeTokens,
} from '../../api/authUtils';
import useMediaSearch from '../../api/chat/useMediaSearch'; // Import del nuovo hook per la ricerca di media

const baseUrl = process.env.REACT_APP_BASE_URL;

// Definizione dei messaggi iniziali per ogni agente
const initialMessages = {
  quiz: "Ciao! Sono l'Esperto di Quiz! Posso creare quiz interattivi e didattici su misura per la tua classe. Indica la classe, il livello, l'argomento e l'obiettivo specifico per iniziare a costruire insieme il quiz perfetto. ✏️",
  laboratori: "Ciao! Sono l'Esperto di Laboratori. Ideo laboratori pratici e coinvolgenti per i tuoi studenti. Specifica la classe, l'argomento e i materiali che hai a disposizione, così potrò offrirti la proposta ideale. 🔍",
  attività: "Salve! Sono l'Esperto di Attività Didattiche. Insieme possiamo creare attività innovative e su misura per i tuoi studenti. Indica la classe, l'argomento e gli obiettivi che vuoi raggiungere per ricevere suggerimenti personalizzati. 💡",
  metodologie: "Buongiorno! Sono l'Esperto di Metodologie Didattiche. Scopriamo insieme nuove metodologie educative adatte alle tue esigenze. Dimmi il contesto della tua classe e l'argomento su cui vuoi lavorare per suggerimenti mirati. 📘",
  montessori: "Benvenuto! Sono l'Esperto del Metodo Montessori. Posso guidarti nell'applicazione dei principi montessoriani. Specifica l'età dei tuoi alunni, i tuoi obiettivi e le loro necessità per consigli su misura. 🌼",
  malaguzzi: "Ciao! Sono l'Esperto del Reggio Emilia Approach, ispirato a Loris Malaguzzi. Ti aiuterò a sviluppare attività che seguono questo approccio educativo. Fornisci informazioni sugli studenti e le attività che vuoi realizzare per suggerimenti precisi e pertinenti. 🌱",
  scrittura: "Ciao! Sono l'Esperto di Scrittura di documenti scolastici. Posso aiutarti a redigere o migliorare verbali, UDA, schede didattiche e altri documenti amministrativi per la tua scuola. Dimmi di cosa hai bisogno e iniziamo a lavorare insieme! 🖋️",
};

const Chat = () => {
  // Variabili di stato
  const [chats, setChats] = useState([]);
  const [files, setFiles] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [didatticaOpen, setDidatticaOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('attività');
  const [agentStartOpen, setAgentStartOpen] = useState(false);
  const [limitError, setLimitError] = useState(null);
  const [shouldScroll, setShouldScroll] = useState(false);

  const navigate = useNavigate();
  const containerRef = useRef(null);

  // Stato per la sidebar mobile
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Rileva se il dispositivo è mobile
  const isMobile = useMediaQuery('(max-width:600px)');

  // Import del nuovo hook per la ricerca di media
  const { searchMedia } = useMediaSearch();

  // Funzione per aggiornare il token di accesso
  const refreshAccessToken = async () => {
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
      removeTokens();
      navigate('/login');
      return null;
    }

    try {
      const response = await fetch(`${baseUrl}/api/accounts/token/refresh/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh: refreshToken }),
      });

      if (!response.ok) {
        throw new Error('Failed to refresh token');
      }

      const data = await response.json();
      setAccessToken(data.access);
      if (data.refresh) {
        setRefreshToken(data.refresh);
      }
      return data.access;
    } catch (error) {
      console.error('Error refreshing token:', error);
      removeTokens();
      navigate('/login');
      return null;
    }
  };

  // Funzione per effettuare richieste autenticate
  const makeAuthenticatedRequest = async (url, options = {}) => {
    let accessToken = getAccessToken();

    // Se non c'è un token di accesso, reindirizza al login
    if (!accessToken) {
      navigate('/login');
      return null;
    }

    // Imposta l'intestazione Authorization
    const headers = {
      ...options.headers,
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      let response = await fetch(url, { ...options, headers });

      // Se non autorizzato, prova a rinfrescare il token
      if (response.status === 401 || response.status === 403) {
        accessToken = await refreshAccessToken();
        if (!accessToken) {
          return null;
        }

        // Ripeti la richiesta originale con il nuovo token
        const retryHeaders = {
          ...options.headers,
          Authorization: `Bearer ${accessToken}`,
        };
        response = await fetch(url, { ...options, headers: retryHeaders });
      }

      return response;
    } catch (error) {
      console.error('Error making authenticated request:', error);
      return null;
    }
  };

  // Recupera le chat al montaggio del componente
  useEffect(() => {
    const accessToken = getAccessToken();

    if (!accessToken) {
      navigate('/login');
      return;
    }

    fetchChats(accessToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  // Auto-scroll quando i messaggi cambiano
  useEffect(() => {
    if (shouldScroll && containerRef.current) {
      setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
      }, 100);
      setShouldScroll(false);
    } else {
      // Leggera scroll per rendere visibile il nuovo messaggio utente
      const scrollAmount = 150;
      if (containerRef.current) {
        containerRef.current.scrollTop += scrollAmount;
      }
    }
  }, [messages, selectedChat, shouldScroll]);

  // Gestisce le risposte di errore dalle API
  const handleErrorResponse = async (response) => {
    if (!response) return true; // Errore gestito (nessuna risposta)

    if (response.status === 401) {
      navigate('/login');
      return true;
    }

    if (response.status === 403 || response.status === 429) {
      const errorData = await response.json();
      if (errorData && errorData.detail && errorData.detail.includes('limite')) {
        setLimitError(errorData.detail);
        return true;
      } else {
        navigate('/abbonamento');
        return true;
      }
    }

    return false; // Errore non gestito
  };

  // Recupera le chat dall'API
  const fetchChats = async (token) => {
    try {
      const response = await makeAuthenticatedRequest(`${baseUrl}/api/chat/chats/`, {
        method: 'GET',
      });

      if (!response) return;

      if (response.status === 401) {
        navigate('/login');
        return;
      }

      if (response.status === 403) {
        navigate('/abbonamento');
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error fetching chats:', errorData);
        return;
      }

      const data = await response.json();
      const sortedChats = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setChats(sortedChats);

      if (sortedChats.length === 0) {
        // Se la lista delle chat è vuota, apri l'interfaccia per selezionare un agente
        handleNewChat();
      } else {
        const currentChat = sortedChats.find((c) => c.id === selectedChat?.id);
        if (currentChat && selectedChat?.id !== currentChat.id) {
          selectChat(currentChat);
        } else if (sortedChats.length > 0 && !currentChat) {
          selectChat(sortedChats[0]);
        }
      }
    } catch (error) {
      console.error('Error fetching chats:', error);
    }
  };

  // Recupera i messaggi per una chat e un agente specifici
  const fetchMessages = async (chatId, agent) => {
    setLoading(true);
    try {
      const response = await makeAuthenticatedRequest(
        `${baseUrl}/api/chat/chats/${chatId}/messages/${agent}/?include=messages`,
        {
          method: 'GET',
        }
      );

      if (!response) {
        setLoading(false);
        return;
      }

      if (response.status === 401 || response.status === 403) {
        navigate('/login');
        setLoading(false);
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error fetching messages:', errorData);
        setLoading(false);
        return;
      }

      const data = await response.json();
      let fetchedMessages = data.messages;

      // Verifica se il messaggio iniziale è già presente
      const initialMessageText = initialMessages[agent] || 'Benvenuto!';
      const hasInitialMessage = fetchedMessages.some(
        (msg) => msg.text === initialMessageText && msg.sender === 'assistant'
      );
      // Se non ci sono messaggi, aggiungi il messaggio iniziale
      if (fetchedMessages.length === 0 || !hasInitialMessage) {
        const initialMessage = {
          id: `initial-${chatId}`, // ID unico per ogni chat
          chat_id: chatId,
          text: initialMessageText,
          sender: 'assistant',
          isInitial: true, // Aggiungi il flag isInitial
        };
        fetchedMessages = [initialMessage, ...fetchedMessages];
      }

      setMessages(fetchedMessages);
      setShouldScroll(true);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching messages:', error);
      setLoading(false);
    }
  };

  // Crea una nuova chat con un agente specifico
  const handleNewChat = async (agentOption = selectedOption, fromAgentStart = false) => {
    setSelectedOption(agentOption); // Aggiorna l'opzione selezionata

    if (!fromAgentStart && agentOption === selectedOption) {
      setAgentStartOpen(true);
      return;
    }

    const accessToken = getAccessToken();
    if (!accessToken) return;

    try {
      const response = await makeAuthenticatedRequest(`${baseUrl}/api/chat/chats/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ agent: agentOption }),
      });

      const errorHandled = await handleErrorResponse(response);
      if (errorHandled) return;

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error fetching chats:', errorData);
        return;
      }

      const data = await response.json();
      setChats([data, ...chats]);
      selectChat(data);
    } catch (error) {
      console.error('Error creating new chat:', error);
    }
  };

  // Elimina una chat specifica
  const handleDeleteChat = async (chatId) => {
    const accessToken = getAccessToken();
    if (!accessToken) return;

    try {
      const response = await makeAuthenticatedRequest(`${baseUrl}/api/chat/chats/${chatId}/delete/`, {
        method: 'DELETE',
      });

      if (!response) return;

      if (response.status === 401 || response.status === 403) {
        navigate('/login');
        return;
      }

      if (response.ok) {
        const updatedChats = chats.filter((chat) => chat.id !== chatId);
        setChats(updatedChats);
        if (updatedChats.length > 0) {
          selectChat(updatedChats[0]);
        } else {
          setSelectedChat(null);
          setMessages([]);
        }
      } else {
        console.error('Error deleting chat');
      }
    } catch (error) {
      console.error('Error deleting chat:', error);
    }
  };

  // Invia un nuovo messaggio
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() && files.length === 0) return;
    if (!selectedChat) return;

    const accessToken = getAccessToken();
    if (!accessToken) return;

    const userMessage = {
      id: Date.now(),
      chat_id: selectedChat.id,
      text: newMessage,
      sender: 'user',
      attachments: files.map((file) => ({
        file_name: file.name,
      })),
    };

    // Aggiungi il messaggio dell'utente e un placeholder per l'assistente
    setMessages((prevMessages) => [
      ...prevMessages,
      userMessage,
      {
        id: Date.now() + 1,
        chat_id: selectedChat.id,
        text: '',
        sender: 'assistant',
        isTyping: true,
      },
    ]);
    setNewMessage('');
    setFiles([]);

    try {
      const formData = new FormData();
      formData.append('text', newMessage);

      files.forEach((file) => {
        if (file.blob) {
          formData.append('files', file.blob, file.name);
        } else {
          formData.append('files', file);
        }
      });

      const response = await makeAuthenticatedRequest(
        `${baseUrl}/api/chat/chats/${selectedChat.id}/messages/${selectedOption}/`,
        {
          method: 'POST',
          body: formData,
        }
      );

      const errorHandled = await handleErrorResponse(response);
      if (errorHandled) {
        setMessages((prevMessages) => prevMessages.slice(0, -1)); // Rimuovi il placeholder dell'assistente
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error sending message:', errorData);
        setMessages((prevMessages) => prevMessages.slice(0, -1));
        return;
      }

      const data = await response.json();
      const agentMessage = data.message;

      // Aggiorna il messaggio dell'assistente
      setMessages((prevMessages) => {
        const lastMessage = prevMessages[prevMessages.length - 1];
        if (lastMessage && lastMessage.sender === 'assistant') {
          return [
            ...prevMessages.slice(0, -1),
            {
              ...lastMessage,
              text: agentMessage,
              isTyping: false,
            },
          ];
        } else {
          return prevMessages;
        }
      });

      await fetchChats(accessToken);
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages((prevMessages) => prevMessages.slice(0, -1));
    }
  };

  // Gestisce la ricerca di media per un messaggio
  const handleMediaSearch = async (message, mediaType) => {
    try {
      // Imposta lo stato di caricamento dei media
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === message.id ? { ...msg, mediaLoading: true } : msg
        )
      );

      // Esegui la ricerca di media tramite il hook
      const mediaData = await searchMedia(message.text, mediaType);

      // Ottieni l'array di media in base al tipo selezionato
      const mediaArray = mediaData[`${mediaType}s`] || mediaData;

      // Aggiorna il messaggio con i media ricevuti
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === message.id
            ? { ...msg, media: mediaArray, mediaLoading: false }
            : msg
        )
      );
    } catch (error) {
      console.error('Errore durante la ricerca dei media:', error);
      // Resetta lo stato di caricamento in caso di errore
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === message.id ? { ...msg, mediaLoading: false } : msg
        )
      );
    }
  };

  // Gestisce il cambio dell'opzione (agente)
  const handleOptionChange = async (option) => {
    setSelectedOption(option);
    setDidatticaOpen(false);
    await handleNewChat(option);
  };

  // Seleziona una chat specifica
  const selectChat = (chat) => {
    if (selectedChat?.id !== chat.id) {
      setSelectedChat(chat);
      setSelectedOption(chat.agent); // Aggiorna l'opzione selezionata in base all'agente della chat
    }
  };

  // Recupera i messaggi quando la chat o l'agente selezionato cambia
  useEffect(() => {
    if (selectedChat) {
      fetchMessages(selectedChat.id, selectedOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChat, selectedOption]);

  // Gestisce il logout dell'utente
  const handleLogout = () => {
    removeTokens();
    navigate('/login');
  };

  const handleCloseReachedLimit = () => {
    setLimitError(null);
  };

  useEffect(() => {
    const handleResize = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (limitError) {
    return <ReachedLimit errorMessage={limitError} onClose={handleCloseReachedLimit} />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        height: 'calc(var(--vh, 1vh) * 100)',
        backgroundColor: '#F3F6FB',
        fontFamily: 'Inter, sans-serif',
        position: 'relative',
      }}
    >
      {isMobile ? (
        <>
          <MobileHeader
            didatticaOpen={didatticaOpen}
            setDidatticaOpen={setDidatticaOpen}
            selectedOption={selectedOption}
            handleOptionChange={handleOptionChange}
            toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
            handleNewChat={handleNewChat}
          />
          {sidebarOpen && (
            <MobileSidebar
              chats={chats}
              selectedChat={selectedChat}
              selectChat={selectChat}
              handleNewChat={handleNewChat}
              handleDeleteChat={handleDeleteChat}
              handleLogout={handleLogout}
              navigate={navigate}
              selectedOption={selectedOption}
              onClose={() => setSidebarOpen(false)}
            />
          )}
        </>
      ) : (
        <>
          <Sidebar
            chats={chats}
            selectedChat={selectedChat}
            selectChat={selectChat}
            handleNewChat={handleNewChat}
            handleDeleteChat={handleDeleteChat}
            handleLogout={handleLogout}
            navigate={navigate}
            selectedOption={selectedOption}
          />

          {selectedOption === 'montessori' || selectedOption === 'malaguzzi' ? (
            <Box
              sx={{
                position: 'fixed',
                left: '20%',
                top: '2.5rem',
                display: 'flex',
                alignItems: 'center',
                zIndex: 2000,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#DF4634',
                  color: '#FFFFFF',
                  borderRadius: '1.875rem',
                  width: '7rem',
                  height: '2.2rem',
                  textTransform: 'none',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  '&:hover': {
                    backgroundColor: '#E57373',
                  },
                }}
              >
                {selectedOption.charAt(0).toUpperCase() + selectedOption.slice(1)}
              </Button>
            </Box>
          ) : (
            <AgentSwitch
              didatticaOpen={didatticaOpen}
              setDidatticaOpen={setDidatticaOpen}
              selectedOption={selectedOption}
              handleOptionChange={handleOptionChange}
            />
          )}
        </>
      )}
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: isMobile ? '1rem' : '3.125rem',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <ChatMessages
          messages={messages}
          loading={loading}
          containerRef={containerRef}
          chatTitle={selectedChat?.title || 'Chat'}
          selectedOption={selectedOption}
          handleMediaSearch={handleMediaSearch} // Passa la funzione aggiornata come prop
        />
        <MessageInput
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          handleSendMessage={handleSendMessage}
          files={files}
          setFiles={setFiles}
        />

        {/* Overlay quando il sottomenu di AgentSwitch è attivo */}
        {didatticaOpen && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              zIndex: 1500,
              pointerEvents: 'none',
            }}
          />
        )}

        {/* Overlay quando AgentStart è attivo */}
        {agentStartOpen &&
          (isMobile ? (
            <AgentStartMobile
              open={agentStartOpen}
              onClose={() => setAgentStartOpen(false)}
              handleNewChat={(agentKey) => {
                setAgentStartOpen(false);
                handleNewChat(agentKey, true);
              }}
            />
          ) : (
            <AgentStart
              open={agentStartOpen}
              onClose={() => setAgentStartOpen(false)}
              handleNewChat={(agentKey) => {
                setAgentStartOpen(false);
                handleNewChat(agentKey, true);
              }}
            />
          ))}
      </Box>
    </Box>
  );
};

export default Chat;
