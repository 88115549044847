// SidebarContent.js
import React from 'react';
import { Box, Button, Typography, List, ListItem, ListItemText, IconButton } from '@mui/material';

const SidebarContent = ({
  chats,
  selectedChat,
  selectChat,
  handleNewChat,
  handleDeleteChat,
  handleLogout,
  navigate,
  selectedOption,
  onClose, // Aggiungi onClose alle props
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1, // Permette al contenuto di espandersi e contrarsi
        overflow: 'hidden', // Nasconde l'overflow per evitare che il contenuto esca dai limiti
      }}
    >
      {/* Contenitore per il logo e il titolo */}
      <Box
        sx={{
          padding: '1rem',
        }}
      >
        {/* Contenitore per testo "Minerva AI" e logo */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h5" sx={{ fontWeight: 400, marginRight: '1rem' }}>
            Minerva AI
          </Typography>

          {/* Cerchio rosso con logo al centro */}
          <Box
            sx={{
              width: '2.5rem',
              height: '2.5rem',
              backgroundColor: '#DF4634',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
            }}
          >
            <Box
              component="img"
              src="/logo/logobianco.svg" // Assicurati che il percorso del logo sia corretto
              alt="Logo"
              sx={{
                width: '2.8rem',
                height: '2.8em',
              }}
            />
          </Box>
        </Box>
      </Box>

      {/* Lista delle conversazioni */}
      <Box
        sx={{
          flexGrow: 1, // Questo permette alla lista di occupare lo spazio rimanente
          overflowY: 'auto', // Abilita lo scroll verticale se il contenuto eccede l'altezza disponibile
          padding: '0 1rem',
        }}
      >
        {/* Titolo "Conversazioni" sopra la lista delle chat */}
        <Typography
          sx={{
            color: '#6B7280',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: 'normal',
            textAlign: 'left',
            width: '100%',
            marginBottom: '0.5rem',
          }}
        >
          Conversazioni
        </Typography>

        <List>
          {chats?.map((chat) => (
            <ListItem
              key={chat.id}
              button
              onClick={() => {
                selectChat(chat);
                onClose(); // Chiudi la sidebar dopo aver selezionato la chat
              }}
              sx={{
                padding: '0.625rem 0rem',
                borderRadius: '1.875rem',
                marginBottom: '0.625rem',
                position: 'relative',
                '&:hover': {
                  backgroundColor: 'rgba(223, 70, 52, 0.1)',
                },
                '&:hover .delete-icon': {
                  visibility: 'visible',
                },
              }}
            >
              <Box
                component="img"
                src={selectedChat?.id === chat.id ? '/chat_icon/chatred.png' : '/chat_icon/chat.png'}
                alt="Chat Icon"
                sx={{
                  width: '1rem',
                  height: '1rem',
                  marginRight: '0.5rem',
                }}
              />
              <ListItemText
                primary={chat.title || `Nuova Chat`}
                sx={{
                  color: selectedChat?.id === chat.id ? '#DF4634' : 'inherit',
                  fontWeight: selectedChat?.id === chat.id ? 'bold' : 'normal',
                  fontSize: '0.875rem',
                  maxWidth: '20ch',
                  wordBreak: 'break-word',
                }}
              />
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteChat(chat.id);
                }}
                className="delete-icon"
                sx={{
                  position: 'absolute',
                  right: '0.625rem',
                  visibility: 'hidden',
                }}
              >
                <img
                  src="/chat_icon/remove.png"
                  alt="Remove Chat"
                  style={{ width: '1rem', height: '1rem' }}
                />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Bottoni in fondo al sidebar */}
      <Box
        sx={{
          padding: '1rem',
          borderTop: '1px solid #e0e0e0',
        }}
      >
        <Button
          variant="outlined"
          sx={{
            borderRadius: '3.125rem',
            borderColor: '#DF4634',
            color: '#000',
            width: '100%',
            marginBottom: '0.625rem',
            textTransform: 'none',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            '&:hover': {
              borderColor: '#DF4634',
              backgroundColor: 'rgba(223, 70, 52, 0.1)',
            },
          }}
          onClick={() => {
            navigate('/');
            onClose(); // Chiudi la sidebar dopo la navigazione
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <img
              src="/chat_icon/home.png"
              alt="Home Icon"
              style={{ width: '1rem', height: '1rem', marginRight: '0.625rem' }}
            />
            Home
          </Box>
        </Button>

        <Button
          variant="outlined"
          sx={{
            borderRadius: '3.125rem',
            borderColor: '#DF4634',
            color: '#000',
            width: '100%',
            marginBottom: '0.625rem',
            textTransform: 'none',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            '&:hover': {
              borderColor: '#DF4634',
              backgroundColor: 'rgba(223, 70, 52, 0.1)',
            },
          }}
          onClick={() => {
            navigate('/profile');
            onClose(); // Chiudi la sidebar dopo la navigazione
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <img
              src="/chat_icon/profile.png"
              alt="Profile Icon"
              style={{ width: '1rem', height: '1rem', marginRight: '0.625rem' }}
            />
            Profilo
          </Box>
        </Button>

        <Button
          variant="outlined"
          sx={{
            borderRadius: '3.125rem',
            borderColor: '#DF4634',
            color: '#000',
            width: '100%',
            textTransform: 'none',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            '&:hover': {
              borderColor: '#DF4634',
              backgroundColor: 'rgba(223, 70, 52, 0.1)',
            },
          }}
          onClick={() => {
            navigate('/resources');
            onClose(); // Chiudi la sidebar dopo la navigazione
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <img
              src="/chat_icon/exit.png"
              alt="Exit Icon"
              style={{ width: '1rem', height: '1rem', marginRight: '0.625rem' }}
            />
            Risorse
          </Box>
        </Button>
      </Box>
    </Box>
  );
};

export default SidebarContent;
