import { useState } from 'react';
import apiClient from '../apiClient';

const useMediaSearch = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Esegue la ricerca di media (immagini, video, PDF) utilizzando un messaggio di input.
   * @param {string} message - Il messaggio di input per generare le query di ricerca.
   * @param {string} mediaType - Il tipo di media da cercare ('image', 'video', 'pdf').
   * @returns {Array} - Lista di URL dei risultati trovati.
   */
  const searchMedia = async (message, mediaType = 'image') => {
    if (!message) return [];
    setLoading(true);
    setError(null);

    try {
      const response = await apiClient.post('/api/chat/media-search/', { message, media_type: mediaType });
      console.log('Media trovati:', response.data);
      return response.data[`${mediaType}s`];  // Ritorna l'array specifico in base al tipo di media
    } catch (err) {
      setError(err.response?.data?.error || 'Errore nella ricerca dei media');
      console.error('Errore nella ricerca dei media:', err);
      return [];
    } finally {
      setLoading(false);
    }
  };

  return { searchMedia, loading, error };
};

export default useMediaSearch;
