// src/api/subscription/UpdateSubscriptionCartaDocente.js
import { useState } from 'react';
import apiClient from '../apiClient';

const useUpdateSubscriptionCartaDocente = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const updateSubscription = async (importo) => {
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const response = await apiClient.post('/api/accounts/update-subscription/', { importo });
      setSuccessMessage(response.data.message);  // Imposta il messaggio di successo
      return response.data.message;
    } catch (err) {
      setError(err.response?.data?.error || 'Errore durante l’aggiornamento dell’abbonamento con Carta Docente');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { updateSubscription, successMessage, loading, error };
};

export default useUpdateSubscriptionCartaDocente;
