// src/api/apiClient.js
import axios from 'axios';
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
  removeTokens,
} from './authUtils';
import { createBrowserHistory } from 'history';

const baseUrl = process.env.REACT_APP_BASE_URL;

// Crea un oggetto history per navigazione programmata
const history = createBrowserHistory();

// Lista degli endpoint che non richiedono autenticazione
const openEndpoints = [
  '/api/accounts/register/',
  '/api/accounts/login/',
  '/api/accounts/google-login/',
  '/api/accounts/password-reset/',
  // Aggiungi altri endpoint aperti qui, se necessario
];

// Crea un'istanza di Axios
const apiClient = axios.create({
  baseURL: baseUrl,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Variabili per gestire il refresh token in corso
let isRefreshing = false;
let failedQueue = [];

// Funzione per processare la coda di richieste fallite
const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Interceptor per aggiungere il token alle richieste, escludendo gli endpoint aperti
apiClient.interceptors.request.use(
  (config) => {
    const token = getAccessToken();

    // Verifica se l'URL della richiesta è tra gli openEndpoints
    const isOpenEndpoint = openEndpoints.some((endpoint) => config.url.startsWith(endpoint));

    if (token && !isOpenEndpoint) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Interceptor per gestire le risposte
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Se l'errore è 401 o 403, e non è già stata tentata una richiesta di retry
    if (
      (error.response && (error.response.status === 401 || error.response.status === 403)) &&
      !originalRequest._retry &&
      !originalRequest.url.includes('/api/accounts/token/refresh/')
    ) {
      console.log('Errore 401/403 ricevuto.');

      const refreshToken = getRefreshToken();
      const accessToken = getAccessToken();

      // Se l'utente è autenticato (ha token) e c'è un refresh token
      if (accessToken && refreshToken) {
        console.log('Utente autenticato, tentativo di refresh del token...');

        if (isRefreshing) {
          console.log('Refresh token già in corso, accodo la richiesta...');
          // Se il refresh è già in corso, aggiungi la richiesta alla coda
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers['Authorization'] = 'Bearer ' + token;
              return apiClient(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
          console.log('Inizio il refresh del token...');
          const response = await axios.post(`${baseUrl}/api/accounts/token/refresh/`, {
            refresh: refreshToken,
          });

          const newAccessToken = response.data.access;
          const newRefreshToken = response.data.refresh;

          console.log('Refresh del token completato con successo, impostazione dei nuovi token...');
          setAccessToken(newAccessToken);
          setRefreshToken(newRefreshToken);

          // Aggiorna l'header Authorization per le future richieste
          apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + newAccessToken;
          originalRequest.headers['Authorization'] = 'Bearer ' + newAccessToken;

          processQueue(null, newAccessToken);
          return apiClient(originalRequest);
        } catch (err) {
          console.log('Errore durante il refresh del token, rimuovo i token e reindirizzo al login...');
          processQueue(err, null);
          removeTokens();
          history.push('/login');
          window.location.reload();
          return Promise.reject(err);
        } finally {
          isRefreshing = false;
        }
      } else {
        // Utente non autenticato, non fare nulla
        console.log('Utente non autenticato, nessuna azione intrapresa.');
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
