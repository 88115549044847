// src/api/documents/useRemoveDocument.js
import { useState } from 'react';
import apiClient from '../apiClient';

const useRemoveDocument = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Rimuove un documento utente specifico.
   *
   * @param {number} documentId - L'ID del documento da rimuovere.
   * @returns {void}
   */
  const removeDocument = async (documentId) => {
    setLoading(true);
    setError(null);
    try {
      await apiClient.delete(`/api/accounts/documents/remove/${documentId}/`);
      alert('Documento rimosso con successo');
    } catch (err) {
      const errorMessage = err.response?.data?.error || 'Errore nella rimozione del documento';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return { removeDocument, loading, error };
};

export default useRemoveDocument;