// src/components/LoadingDots.js

import React from 'react';
import { Box } from '@mui/material';

const LoadingDots = () => {
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        '& span': {
          width: '12px',
          height: '12px',
          backgroundColor: '#DF4634',
          borderRadius: '50%',
          marginRight: '8px',
          animation: 'blink 1.4s infinite both',
        },
        '& span:nth-of-type(2)': {
          animationDelay: '0.2s',
        },
        '& span:nth-of-type(3)': {
          animationDelay: '0.4s',
          marginRight: 0,
        },
        '@keyframes blink': {
          '0%': {
            opacity: 0.2,
          },
          '20%': {
            opacity: 1,
          },
          '100%': {
            opacity: 0.2,
          },
        },
      }}>
        <span></span>
        <span></span>
        <span></span>
      </Box>
    </Box>
  );
};

export default LoadingDots;
