// src/components/Login.js
import React, { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Box, Button, TextField, Typography, Link, Alert } from '@mui/material';
import GoogleButton from './GoogleButton';
import useLogin from '../api/auth/useLogin';
import { jwtDecode } from 'jwt-decode'; // Corretto import
import apiClient from '../api/apiClient';
import { setAccessToken, setRefreshToken } from '../api/authUtils';
import useGetSubscription from '../api/subscription/useGetSubscription'; // Importa il tuo hook

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();
  const { login, loading, error } = useLogin();
  const { subscription, loading: subscriptionLoading, error: subscriptionError, refetch: refetchSubscription } = useGetSubscription();

  const handleLogin = async (e) => {
    e.preventDefault();

    const userData = {
      email,
      password,
    };

    try {
      console.log('Attempting to login...');
      await login(userData); // Effettua il login
      setSuccess(true);

      // Dopo il login, fetch delle subscription
      await refetchSubscription();

    } catch (error) {
      console.error('Login failed:', error);
      setSuccess(false);
      // L'errore è già gestito dallo stato del hook
    }
  };

  useEffect(() => {
    if (success && !subscriptionLoading) {
      if (subscription) {
        if (subscription.is_subscribed) {
          navigate('/');
        } else {
          navigate('/abbonamento');
        }
      }
    }
  }, [success, subscription, subscriptionLoading, navigate]);

  // Funzione per gestire il login con Google
  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const { credential } = credentialResponse;
      const decoded = jwtDecode(credential);
      const access_token = credential;

      // Invia il token al backend
      const response = await apiClient.post('/api/accounts/social/google/', { access_token });

      const { tokens, needs_additional_info } = response.data;

      // Salva i token
      setAccessToken(tokens.access);
      setRefreshToken(tokens.refresh);

      if (needs_additional_info) {
        navigate('/complete-profile');
      } else {
        // Dopo il login con Google, fetch delle subscription
        await refetchSubscription();

        if (subscription) {
          if (subscription.is_subscribed) {
            navigate('/');
          } else {
            navigate('/abbonamento');
          }
        }
      }
    } catch (error) {
      console.error('Errore durante il login con Google:', error);
      // Puoi mostrare un Alert o un messaggio di errore qui
    }
  };

  const handleGoogleFailure = (error) => {
    console.error('Login con Google fallito:', error);
    // Puoi mostrare un Alert o un messaggio di errore qui
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#F3F6FB',
        position: 'relative',
        padding: '20px',
        fontFamily: 'Inter, sans-serif',
      }}
    >
      <RouterLink to="/">
        <img
          src="/logo/logogrey.svg"
          alt="Logo"
          style={{
            width: '83px',
            height: '76px',
            marginBottom: '-20px',
          }}
        />
      </RouterLink>
      <Box
        component="form"
        onSubmit={handleLogin}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: 320,
          padding: 2,
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 600 }}>
          Bentornato
        </Typography>
        {error && (
          <Alert severity="error" sx={{ width: '100%', marginBottom: 2 }}>
            Credenziali di accesso errate
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ width: '100%', marginBottom: 2 }}>
            Accesso completato! Reindirizzamento...
          </Alert>
        )}
        <TextField
          label="Email address"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
          sx={{
            width: '320px',
            height: '64px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '30px',
              '& fieldset': {
                borderColor: '#DF4634',
              },
              '&:hover fieldset': {
                borderColor: '#DF4634',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#DF4634',
              },
            },
            '& .MuiInputLabel-root': {
              color: '#DF4634',
              fontFamily: 'Inter, sans-serif',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#DF4634',
            },
          }}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{
            width: '320px',
            height: '64px',
            marginTop: '8px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '30px',
              '& fieldset': {
                borderColor: '#DF4634',
              },
              '&:hover fieldset': {
                borderColor: '#DF4634',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#DF4634',
              },
            },
            '& .MuiInputLabel-root': {
              color: '#DF4634',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#DF4634',
            },
          }}
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={loading || subscriptionLoading} // Disabilita durante il caricamento del login o della subscription
          sx={{
            width: 320,
            height: 50,
            backgroundColor: '#DF4634',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '30px',
            textTransform: 'none',
            fontSize: '15px',
            lineHeight: '27px',
            color: '#FFFFFF',
            marginTop: '8px',
            '&:hover': {
              backgroundColor: '#DF4634',
            },
          }}
        >
          {loading ? 'Accesso in corso...' : 'Accedi'}
        </Button>
      </Box>

      {/* Link per la registrazione e il reset della password */}
      <Typography variant="body2" sx={{ marginTop: 2 }}>
        Non hai un account?{' '}
        <Link
          component={RouterLink}
          to="/signup"
          sx={{
            color: '#DF4634',
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline' },
          }}
        >
          Registrati
        </Link>
        <Typography variant="body2" sx={{ marginTop: 0.2 }}>
          Dimenticato la password?{' '}
          <Link
            component={RouterLink}
            to="/reset-password"
            sx={{
              color: '#DF4634',
              textDecoration: 'none',
              '&:hover': { textDecoration: 'underline' },
            }}
          >
            Reset-password
          </Link>
        </Typography>
      </Typography>
    </Box>
  );
};

export default Login;
