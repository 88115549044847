// src/api/authUtils.js

export const getAccessToken = () => {
  return localStorage.getItem('access') || localStorage.getItem('accessToken');
};

export const getRefreshToken = () => {
  return localStorage.getItem('refresh') || localStorage.getItem('refreshToken');
};

export const setAccessToken = (token) => {
  localStorage.setItem('access', token);
};

export const setRefreshToken = (token) => {
  localStorage.setItem('refresh', token);
};

export const removeTokens = () => {
  localStorage.removeItem('access');
  localStorage.removeItem('refresh');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
};

// Funzione per pulire tutti i token
export const clearTokens = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
};