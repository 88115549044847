import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Header from '../Header';
import MobileMenu from '../MobileMenu';
import Footer from '../Footer';
import FloatingMenu from '../FloatingMenu';
import VideoComponent from './VideoComponentTutorial';
import apiClient from '../../api/apiClient';
import { getAccessToken, getRefreshToken, removeTokens, setAccessToken } from '../../api/authUtils';
import { jwtDecode } from 'jwt-decode'; // Corretto l'import
import './styles/Tutorial.css';

const TutorialPage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showFloatingMenu, setShowFloatingMenu] = useState(false);

  useEffect(() => {
    const checkAuthStatus = async () => {
      const accessToken = getAccessToken();
      const refreshToken = getRefreshToken();

      if (accessToken) {
        try {
          const decodedAccessToken = jwtDecode(accessToken);
          const currentTime = Date.now() / 1000;

          if (decodedAccessToken.exp && decodedAccessToken.exp > currentTime) {
            setIsLoggedIn(true);
          } else if (refreshToken) {
            try {
              const response = await apiClient.post('/accounts/token/refresh/', {
                refresh: refreshToken,
              });

              if (response.status === 200) {
                const data = response.data;
                setAccessToken(data.access);
                setIsLoggedIn(true);
              } else {
                removeTokens();
                setIsLoggedIn(false);
              }
            } catch (error) {
              console.error('Errore durante il refresh del token:', error);
              removeTokens();
              setIsLoggedIn(false);
            }
          } else {
            removeTokens();
            setIsLoggedIn(false);
          }
        } catch (error) {
          console.error('Errore durante la decodifica del token di accesso:', error);
          removeTokens();
          setIsLoggedIn(false);
        }
      } else if (refreshToken) {
        try {
          const response = await apiClient.post('/accounts/token/refresh/', {
            refresh: refreshToken,
          });

          if (response.status === 200) {
            const data = response.data;
            setAccessToken(data.access);
            setIsLoggedIn(true);
          } else {
            removeTokens();
            setIsLoggedIn(false);
          }
        } catch (error) {
          console.error('Errore durante il refresh del token:', error);
          removeTokens();
          setIsLoggedIn(false);
        }
      } else {
        setIsLoggedIn(false);
      }
    };

    checkAuthStatus();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowFloatingMenu(true);
      } else {
        setShowFloatingMenu(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  // Array di tutorial per una gestione scalabile
  const tutorials = [
    {
      id: 1,
      title: 'Come Iscriversi e Iniziare Subito',
      description: 'Una guida passo-passo per la tua prima iscrizione.',
      videoSrc: '/video/subscription.mp4',
    },
    {
      id: 2,
      title: 'Tutorial sulle Funzionalità di Minerva',
      description: 'Impara ad utilizzare al meglio la piattaforma.',
      videoSrc: '/video/activate_account.mp4',
    },
    // Puoi aggiungere altri tutorial qui
  ];

  return (
    <div className="tutorial-page">
      <Header
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />

      {mobileMenuOpen && (
        <MobileMenu
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          setMobileMenuOpen={setMobileMenuOpen}
        />
      )}

      {showFloatingMenu && (
        <FloatingMenu
          isLoggedIn={isLoggedIn}
          handleLogout={() => {
            removeTokens();
            setIsLoggedIn(false);
          }}
        />
      )}

      <div className="container_tutorial">
        <main>
          <motion.section
            className="tutorial-section"
            initial="hidden"
            animate="visible"
            variants={sectionVariants}
          >
            <h1>Tutorial</h1>
            <p>
              Benvenuto nella sezione tutorial di Minerva AI. Qui troverai guide utili per iniziare al meglio.
            </p>

            <div className="tutorial-videos">
              {tutorials.map((tutorial) => (
                <div className="tutorial-video" key={tutorial.id}>
                  <h2>{tutorial.title}</h2>
                  <p>{tutorial.description}</p>
                  <VideoComponent videoSrc={tutorial.videoSrc} />
                </div>
              ))}
            </div>
          </motion.section>
        </main>
      </div>

      <Footer />
    </div>
  );
};

export default TutorialPage;
