// src/api/subscription/useCreateCheckoutSession.js
import { useState } from 'react';
import apiClient from '../apiClient';

const useCreateCheckoutSession = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sessionId, setSessionId] = useState(null);

  const createSession = async (subscriptionType) => {
    setLoading(true);
    setError(null);
    setSessionId(null);
    try {
      const response = await apiClient.post('/api/accounts/create-checkout-session/', { subscription_type: subscriptionType });
      setSessionId(response.data.sessionId);
      return response.data.sessionId;
    } catch (err) {
      setError(err.response?.data?.error || 'Errore nella creazione della sessione di checkout');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { createSession, sessionId, loading, error };
};

export default useCreateCheckoutSession;
