// src/components/Presentation.js
import React from 'react';
import { motion } from 'framer-motion';
import './styles/Presentation.css';

const Presentation = () => {
  return (
    <div className="image-container">
      <video
        className="background-video"
        src="/video/presentation.mp4"
        autoPlay
        loop
        muted
        playsInline
      />
      <motion.img
        src="/home_icon/teacher.png"
        alt="Insegnante"
        className="teacher-image"
        initial={{ x: '-100%', opacity: 0 }}
        animate={{ x: '0%', opacity: 1 }}
        transition={{ duration: 1 }}
      />
    </div>
  );
};

export default Presentation;

