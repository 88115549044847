// src/api/auth/useSupportEmail.js

import { useState } from 'react';
import apiClient from '../apiClient';

const useSupportEmail = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const sendSupportEmail = async (supportMessage) => {
    setLoading(true);
    setError(null);
    setMessage(null);
    try {
      const response = await apiClient.post('/api/accounts/support/', {
        message: supportMessage,
      });
      setMessage(response.data.message || 'Richiesta di supporto inviata con successo!');
      return response.data;
    } catch (err) {
      if (err.response) {
        setError(err.response.data.error || 'Errore nel server durante l\'invio della richiesta.');
      } else if (err.request) {
        setError('Nessuna risposta dal server. Riprova più tardi.');
      } else {
        setError('Errore nell\'invio della richiesta di supporto.');
      }
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { sendSupportEmail, loading, error, message };
};

export default useSupportEmail;
