// src/api/auth/useDeleteAccount.js
import { useState } from 'react';
import apiClient from '../apiClient';

const useDeleteAccount = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const deleteAccount = async () => {
    setLoading(true);
    setError(null);
    setSuccess(false);
    try {
      await apiClient.delete('/api/accounts/account/delete/');
      setSuccess(true);
      return true;
    } catch (err) {
      setError(
        err.response?.data?.error ||
          err.response?.data?.message ||
          'Errore nella cancellazione dell\'account.'
      );
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { deleteAccount, loading, error, success };
};

export default useDeleteAccount;
