// src/api/auth/useRegister.js
import { useState } from 'react';
import apiClient from '../apiClient';

const useRegister = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const register = async (userData) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiClient.post('/api/accounts/register/', userData);
      return response.data;
    } catch (err) {
      // Gestisci l'errore in base alla risposta del backend
      setError(err.response?.data?.error || err.response?.data?.message || 'Errore nella registrazione');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { register, loading, error };
};

export default useRegister;
