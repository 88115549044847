// MediaTypeDialog.js
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from '@mui/material';

const MediaTypeDialog = ({
  open,
  onClose,
  selectedMediaType,
  onConfirm,
}) => {
  const [tempSelectedMediaType, setTempSelectedMediaType] = useState(selectedMediaType);

  // Reimposta tempSelectedMediaType quando il dialogo viene aperto
  useEffect(() => {
    if (open) {
      setTempSelectedMediaType(selectedMediaType);
    }
  }, [open, selectedMediaType]);

  const options = [
    { key: 'image', title: 'Immagini', description: 'Cerca e scarica immagini correlate alla tua richiesta.' },
    { key: 'video', title: 'Video', description: 'Cerca video educativi o correlati all’argomento richiesto.' },
    { key: 'pdf', title: 'PDF', description: 'Scarica documenti PDF per approfondire il contenuto.' },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(255, 255, 255, 0.7)', // Colore di sfondo semitrasparente
          zIndex: 2500, // Z-index elevato per coprire anche la sidebar
        },
        '& .MuiPaper-root': {
          borderRadius: '1.5rem', // Bordo più arrotondato per il dialogo
          zIndex: 2500, // Assicura che il contenuto del dialogo sia sopra il backdrop
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: '#DF4634',
          color: '#FFFFFF',
          textAlign: 'center',
          padding: '1rem 0',
          fontSize: '1.25rem',
          fontWeight: 'bold',
        }}
      >
        Seleziona il tipo di materiale
      </DialogTitle>
      <DialogContent
        sx={{
          backgroundColor: '#FFFFFF',
          padding: '1rem 1.5rem',
          marginTop: '1rem', // Aggiunge spazio tra il primo bottone e la parte superiore
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {options.map((option) => (
            <Box
              key={option.key}
              onClick={() => setTempSelectedMediaType(option.key)}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                backgroundColor: tempSelectedMediaType === option.key ? 'rgba(223, 70, 52, 0.1)' : '#FFFFFF',
                borderRadius: '1.25rem',
                padding: '0.75rem 1rem',
                marginBottom: '0.5rem',
                cursor: 'pointer',
                width: '100%',
                border: '1px solid #DF4634',
                '&:hover': {
                  backgroundColor: 'rgba(223, 70, 52, 0.1)',
                },
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: '#000000',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                  textAlign: 'left',
                }}
              >
                {option.title}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: '#666666',
                  fontSize: '0.85rem',
                  textAlign: 'left',
                }}
              >
                {option.description}
              </Typography>
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: '1rem', justifyContent: 'space-between' }}>
        <Button onClick={onClose} sx={{ color: '#DF4634' }}>
          Annulla
        </Button>
        <Button
          onClick={() => onConfirm(tempSelectedMediaType)}
          variant="contained"
          sx={{
            backgroundColor: '#DF4634',
            color: '#FFFFFF',
            '&:hover': { backgroundColor: '#E57373' },
          }}
        >
          Cerca
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MediaTypeDialog;
