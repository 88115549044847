import React, { useState, useEffect } from 'react';
import { getAccessToken, getRefreshToken, removeTokens, setAccessToken } from '../api/authUtils';
import { jwtDecode } from 'jwt-decode'; // Corretto l'import di jwtDecode
import apiClient from '../api/apiClient';
import './styles/Footer.css';

const Footer = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkAuthStatus = async () => {
      const accessToken = getAccessToken();
      const refreshToken = getRefreshToken();

      if (accessToken) {
        try {
          const decodedAccessToken = jwtDecode(accessToken);
          const currentTime = Date.now() / 1000;

          if (decodedAccessToken.exp && decodedAccessToken.exp > currentTime) {
            setIsLoggedIn(true);
          } else if (refreshToken) {
            try {
              const response = await apiClient.post('/accounts/token/refresh/', {
                refresh: refreshToken,
              });

              if (response.status === 200) {
                const data = response.data;
                setAccessToken(data.access);
                setIsLoggedIn(true);
              } else {
                removeTokens();
                setIsLoggedIn(false);
              }
            } catch (error) {
              console.error('Errore durante il refresh del token:', error);
              removeTokens();
              setIsLoggedIn(false);
            }
          } else {
            removeTokens();
            setIsLoggedIn(false);
          }
        } catch (error) {
          console.error('Errore durante la decodifica del token di accesso:', error);
          removeTokens();
          setIsLoggedIn(false);
        }
      } else if (refreshToken) {
        try {
          const response = await apiClient.post('/accounts/token/refresh/', {
            refresh: refreshToken,
          });

          if (response.status === 200) {
            const data = response.data;
            setAccessToken(data.access);
            setIsLoggedIn(true);
          } else {
            removeTokens();
            setIsLoggedIn(false);
          }
        } catch (error) {
          console.error('Errore durante il refresh del token:', error);
          removeTokens();
          setIsLoggedIn(false);
        }
      } else {
        setIsLoggedIn(false);
      }
    };

    checkAuthStatus();
  }, []);

  return (
    <footer className="footer">
      <div className="container">
        {!isLoggedIn && (
          <div className="cta-button">
            <a href="/signup">
              <button>Prova Minerva</button>
            </a>
          </div>
        )}

        <div className="footer-content">
          <div className="footer-image">
            <img src="/footer/book.png" alt="Libri" />
          </div>

          <div className="contact-info">
            <p className="contact-title">Contatti</p>
            <p className="contact-email">minervaeducationlab@gmail.com</p>
            <p className="company-info">Fastcode Agency | P.IVA: IT03218800609</p>
            <div className="social-icons">
              {/* Link per TikTok */}
              <a 
                href="https://www.tiktok.com/@minervaeducation?_t=8pqeFV9WSwr&_r=1" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <img src="/footer/tiktok.png" alt="TikTok" />
              </a>

              {/* Link per Instagram */}
              <a 
                href="https://www.instagram.com/minervaeducationai?igsh=aXRobzNkcGk1amc4&utm_source=qr" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <img src="/footer/instagram.png" alt="Instagram" />
              </a>

              {/* Link per LinkedIn */}
              <a 
                href="https://www.linkedin.com/in/minervaeducationai" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <img src="/footer/linkedin.png" alt="LinkedIn" />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Sposta il link alla Privacy Policy qui */}
      <div className="privacy-policy">
        <a 
          href="https://www.iubenda.com/privacy-policy/80002115" 
          target="_blank" 
          rel="noopener noreferrer"
          aria-label="Leggi la Privacy Policy"
        >
          Privacy Policy
        </a>
      </div>
    </footer>
  );
};

export default Footer;
