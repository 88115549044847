// src/api/subscription/useGetSubscription.js
import { useState, useEffect, useCallback } from 'react';
import apiClient from '../apiClient';

const useGetSubscription = () => {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchSubscription = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiClient.get('/api/accounts/subscription/');
      setSubscription(response.data);
    } catch (err) {
      setError(err.response?.data?.error || 'Errore nel recupero dell\'abbonamento');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSubscription();
  }, [fetchSubscription]);

  return { subscription, loading, error, refetch: fetchSubscription };
};

export default useGetSubscription;
