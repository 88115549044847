// src/App.js
import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './components/home/Home';
import Login from './components/Login';
import WelcomePage from './components/WelcomePage';
import Signup from './components/Signup';
import Chat from './components/chat/Chat';
import Profile from './components/profile/Profile';
import Subscription from './components/Subscription';
import ResetPassword from './components/ResetPassword';
import CheckoutSuccess from './components/CheckoutSuccess';
import CheckoutCancel from './components/CheckoutCancel';
import ChangePassword from './components/ChangePassword';
import DocumentViewer from './components/DocumentViewer';
import Resources from './components/Resources';
import DocumentDetail from './components/DocumentDetail';
import ActivationSuccess from './components/ActivationSuccess';
import AlreadyActivated from './components/AlreadyActivated';
import TutorialPage from './components/tutorial/Tutorial';
import './App.css';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    const excludedRoutes = ['/chat'];
    const isExcludedRoute = excludedRoutes.includes(location.pathname);
    const scriptId = 'iubenda-script';

    if (!isExcludedRoute) {
      // Carica lo script di Iubenda solo se non è già presente
      if (!document.getElementById(scriptId)) {
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = "//cdn.iubenda.com/cs/iubenda_cs.js";
        script.charset = "UTF-8";
        script.async = true;
        document.body.appendChild(script);
      }
    } else {
      // Rimuove lo script di Iubenda se presente
      const existingScript = document.getElementById(scriptId);
      if (existingScript) {
        existingScript.parentNode.removeChild(existingScript);
      }

      // Rimuove tutti gli elementi aggiunti da Iubenda
      const iubendaElements = document.querySelectorAll('[class*="iubenda"]');
      iubendaElements.forEach(element => element.remove());
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/welcomepage" element={<WelcomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/abbonamento" element={<Subscription />} />
        <Route path="/checkout-success" element={<CheckoutSuccess />} />
        <Route path="/checkout-cancel" element={<CheckoutCancel />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/documents/:documentType" element={<DocumentViewer />} />
        <Route path="/documents/detail/:documentName" element={<DocumentDetail />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/activation-success" element={<ActivationSuccess />} />
        <Route path="/already-activated" element={<AlreadyActivated />} />
        <Route path="/tutorial" element={<TutorialPage />} />
      </Routes>
    </div>
  );
};

export default App;
