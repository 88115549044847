// src/components/SupportModal.js

import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import './styles/SupportModal.css';

const SupportModal = ({
  isOpen,
  onClose,
  onSubmit,
  loading,
  error,
  message,
  userEmail,
}) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>Hai problemi?</h2>
        <p>
          Invia un'email a <strong>minervaeducationlab@gmail.com</strong> o utilizza il modulo sottostante.
        </p>
        <form onSubmit={onSubmit}>
          <TextField
            label="La tua Email"
            type="email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={userEmail}
            disabled
            sx={{
              width: '100%',
              '& .MuiOutlinedInput-root': {
                borderRadius: '30px',
                '& fieldset': {
                  borderColor: '#DF4634',
                },
                '&:hover fieldset': {
                  borderColor: '#DF4634',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#DF4634',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#DF4634',
                fontFamily: 'Inter, sans-serif',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#DF4634',
              },
            }}
          />
          <TextField
            label="Messaggio"
            type="text"
            variant="outlined"
            fullWidth
            margin="normal"
            name="message"
            required
            multiline
            rows={4}
            sx={{
              width: '100%',
              '& .MuiOutlinedInput-root': {
                borderRadius: '30px',
                '& fieldset': {
                  borderColor: '#DF4634',
                },
                '&:hover fieldset': {
                  borderColor: '#DF4634',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#DF4634',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#DF4634',
                fontFamily: 'Inter, sans-serif',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#DF4634',
              },
            }}
          />
          {message && <p className="success-message">{message}</p>}
          {error && <p className="error-message">{error}</p>}
          <div className="modal-buttons">
            <button
              className="confirm-button"
              type="submit"
              disabled={loading}
            >
              {loading ? 'Invio...' : 'Invia'}
            </button>
            <button
              className="cancel-button"
              type="button"
              onClick={onClose}
            >
              Annulla
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

SupportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string,
  message: PropTypes.string,
  userEmail: PropTypes.string.isRequired,
};

export default SupportModal;
