// MediaDisplay.js
import React from 'react';
import { Grid, Box, Typography, Link } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const MediaDisplay = ({ media, selectedMediaType }) => {
  if (!media || media.length === 0) return null;

  const getYouTubeThumbnail = (url) => {
    const videoId = url.split("v=")[1];
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  };

  return (
    <Grid container spacing={2} sx={{ marginTop: '1rem' }}>
      {media.slice(0, 9).map((item, idx) => (
        <Grid item xs={12} sm={6} md={4} key={idx}>
          <a
            href={item.url || item}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            {selectedMediaType === 'image' ? (
              <Box
                component="img"
                src={item.contentUrl || item.url || item}
                alt={item.descrizione || "Immagine"}
                sx={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: '0.5rem',
                  cursor: 'pointer',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              />
            ) : selectedMediaType === 'video' ? (
              <Box
                sx={{
                  position: 'relative',
                  borderRadius: '0.5rem',
                  overflow: 'hidden',
                  cursor: 'pointer',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                <Box
                  component="img"
                  src={getYouTubeThumbnail(item.url)}
                  alt={item.descrizione || "Video"}
                  sx={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                    borderRadius: '0.5rem',
                  }}
                />
                <Typography
                  variant="subtitle1"
                  sx={{
                    marginTop: '0.5rem',
                    fontWeight: 'bold',
                    color: '#333',
                    textAlign: 'center',
                  }}
                >
                  {item.descrizione}
                </Typography>
              </Box>
            ) : selectedMediaType === 'pdf' ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '1rem',
                  height: '220px',
                  borderRadius: '0.5rem',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  backgroundColor: '#fafafa',
                  border: '1px solid #DF4634',
                  cursor: 'pointer',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)',
                  },
                }}
              >
                <PictureAsPdfIcon sx={{ fontSize: 50, color: '#DF4634', marginBottom: '0.5rem' }} />
                <Typography variant="subtitle1" sx={{ textAlign: 'center', color: '#333' }}>
                  {item.descrizione || 'Documento PDF'}
                </Typography>
                <Typography variant="body2" sx={{ textAlign: 'center', color: '#555' }}>
                Fonte:{" "}
                <Link 
                    href={item.fonte.startsWith('http') ? item.fonte : `https://${item.fonte}`} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    underline="hover"
                >
                    {item.fonte ? new URL(item.fonte.startsWith('http') ? item.fonte : `https://${item.fonte}`).hostname : 'Sconosciuta'}
                </Link>
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '150px',
                  backgroundColor: '#f5f5f5',
                  borderRadius: '0.5rem',
                  cursor: 'pointer',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                <span>File</span>
              </Box>
            )}
          </a>
        </Grid>
      ))}
    </Grid>
  );
};

export default MediaDisplay;


