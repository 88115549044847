// src/api/documents/useGetDocuments.js
import { useState, useEffect, useCallback } from 'react';
import apiClient from '../apiClient';

const useGetDocuments = () => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchDocuments = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiClient.get('/api/accounts/documents/');
      // Rimuovi il log di debug o sostituiscilo con un sistema di logging appropriato
      // console.log('Fetched documents:', response.data);

      if (Array.isArray(response.data)) {
        setDocuments(response.data);
      } else if (typeof response.data === 'object' && response.data !== null) {
        setDocuments(Object.values(response.data));
      } else {
        setDocuments([]);
      }
    } catch (err) {
      const errorMessage = err.response?.data?.error || 'Errore nel recupero dei documenti';
      setError(errorMessage);
      // Non è necessario rilanciare l'errore, ma dipende dalla logica della tua applicazione
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  return { documents, loading, error, refetch: fetchDocuments };
};

export default useGetDocuments;
