// AgentStart.js
import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Paper, 
  Button, 
  IconButton, 
  Divider, 
  useMediaQuery, 
  useTheme 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { keyframes } from '@emotion/react';

// Definizione degli agenti primari
const primaryAgents = [
  {
    name: 'Metodologie',
    description: 'Scopri metodologie, con consigli su risorse e spiegazioni.',
    image: '/people/metodologie.png',
    agentKey: 'metodologie',
  },
  {
    name: 'Attività',
    description: 'Crea attività didattiche innovative per la tua classe.',
    image: '/people/attività.png',
    agentKey: 'attività',
  },
  {
    name: 'Quiz',
    description: 'Genera quiz stimolanti per diversi livelli di apprendimento.',
    image: '/people/quiz.png',
    agentKey: 'quiz',
  },
  {
    name: 'Laboratori e progetti',
    description: 'Perfetto per ideare e sviluppare laboratori o progetti stimolanti',
    image: '/people/laboratorio.png',
    agentKey: 'laboratori',
  },
  {
    name: 'Scrittura',
    description: 'Assistenza nella redazione di: UDA, Programmazioni, verbali e altro.',
    image: '/people/scrittura.png',
    agentKey: 'scrittura',
  },
];

// Definizione degli agenti secondari
const secondaryAgents = [
  {
    name: 'Montessori',
    description: 'Scopri il Metodo Montessori per un apprendimento personalizzato.',
    image: '/people/montessori.png',
    agentKey: 'montessori',
  },
  {
    name: 'Malaguzzi',
    description: 'Approfondisci il Metodo Reggio Emilia per lo sviluppo creativo.',
    image: '/people/malaguzzi.png',
    agentKey: 'malaguzzi',
  },
];

// Animazione per i tre puntini
const loadingAnimation = keyframes`
  0% { opacity: 0.2; transform: translateY(0); }
  20% { opacity: 1; transform: translateY(-5px); }
  100% { opacity: 0.2; transform: translateY(0); }
`;

// Componente LoadingIndicator con i tre puntini animati
const LoadingIndicator = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{
        backgroundColor: '#E57373',
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        margin: '0 5px',
        animation: `${loadingAnimation} 1s infinite`,
        animationDelay: '0s',
      }} />
      <Box sx={{
        backgroundColor: '#E57373',
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        margin: '0 5px',
        animation: `${loadingAnimation} 1s infinite`,
        animationDelay: '0.2s',
      }} />
      <Box sx={{
        backgroundColor: '#E57373',
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        margin: '0 5px',
        animation: `${loadingAnimation} 1s infinite`,
        animationDelay: '0.4s',
      }} />
    </Box>
  </Box>
);

// Funzione per precaricare le immagini
const preloadImages = (imageUrls) => {
  return Promise.all(
    imageUrls.map(
      (src) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        })
    )
  );
};

const AgentStart = ({ open, onClose, handleNewChat }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (open) {
      // Raccogli tutti gli URL delle immagini
      const imageUrls = [
        ...primaryAgents.map((agent) => agent.image),
        ...secondaryAgents.map((agent) => agent.image),
      ];

      // Inizia il caricamento delle immagini
      preloadImages(imageUrls)
        .then(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Errore nel caricamento delle immagini:', error);
          setIsLoading(false); // Anche in caso di errore, prosegui per non bloccare l'interfaccia
        });
    } else {
      // Reset dello stato di caricamento quando il componente si chiude
      setIsLoading(true);
    }
  }, [open]);

  if (!open) return null;

  // Durante il caricamento, mostra solo l'animazione
  if (isLoading) {
    return (
      <Box
        sx={{
          position: 'fixed',
          top: '50%',
          left: '60%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '95%', sm: '80%', md: '60%' },
          backgroundColor: 'transparent', // Trasparente per non mostrare il div
          zIndex: 3000,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoadingIndicator />
      </Box>
    );
  }

  // Contenuto principale dopo il caricamento
  return (
    <Box
      sx={{
        position: 'fixed',
        top: '50%',
        left: '60%',
        transform: 'translate(-50%, -50%)',
        width: { xs: '95%', sm: '80%', md: '65%' },
        backgroundColor: '#FFFFFF',
        border: '1px solid #DF4634',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
        borderRadius: '1.875rem',
        padding: isMobile ? '1rem' : '2rem',
        zIndex: 3000,
        maxHeight: '90vh',
        overflowY: 'auto',
        transition: 'all 0.3s ease-in-out',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {/* Header con titolo e bottone di chiusura */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          Scegli l'esperto di cui hai bisogno
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Sezione Primary Agents */}
      <Grid container spacing={2} alignItems="stretch">
        {primaryAgents.map((agent) => (
          <Grid item xs={2.4} key={agent.agentKey} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Paper
              elevation={3}
              sx={{
                padding: '0.75rem',
                borderRadius: '1rem',
                textAlign: 'center',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                transition: 'background-color 0.3s ease',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(223, 70, 52, 0.1)',
                },
              }}
              onClick={() => {
                handleNewChat(agent.agentKey);
                onClose();
              }}
            >
              <Box
                component="img"
                src={agent.image}
                alt={agent.name}
                sx={{
                  width: '3.3rem',
                  height: '3.3rem',
                  margin: '0 auto 0rem',
                }}
              />
              <Typography variant="subtitle1" sx={{ fontWeight: 500, marginBottom: '0.1rem', fontSize: '0.9rem', lineHeight: '1.2' }}>
                {agent.name}
              </Typography>
              <Typography variant="body2" sx={{ color: '#6B7280', flexGrow: 1, fontSize: '0.75rem', lineHeight: '1.2' }}>
                {agent.description}
              </Typography>
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  handleNewChat(agent.agentKey);
                  onClose();
                }}
                sx={{
                  marginTop: '0.4rem',
                  backgroundColor: '#DF4634',
                  color: '#FFFFFF',
                  borderRadius: '1rem',
                  textTransform: 'none',
                  fontSize: '0.75rem',
                  padding: '0.5rem 1rem',
                  '&:hover': {
                    backgroundColor: '#E57373',
                  },
                }}
              >
                Inizia
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Divider */}
      <Box sx={{ my: 2 }}>
        <Divider />
      </Box>

      {/* Sezione Secondary Agents */}
      <Grid container spacing={2} alignItems="stretch" sx={{ paddingBottom: '1rem' }}>
        {secondaryAgents.map((agent) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            key={agent.agentKey}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <Paper
              elevation={3}
              sx={{
                padding: '0.75rem',
                borderRadius: '1rem',
                textAlign: 'center',
                height: '100%', // Riempie l'altezza disponibile
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                transition: 'background-color 0.3s ease',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(223, 70, 52, 0.1)', // Effetto hover
                },
                // maxHeight: '12rem', // Rimosso per evitare limitazioni
              }}
              onClick={() => {
                handleNewChat(agent.agentKey);
                onClose();
              }}
            >
              <Box
                component="img"
                src={agent.image}
                alt={agent.name}
                sx={{
                  width: '2.5rem',
                  height: '2.5rem',
                  margin: '0 auto 0.75rem',
                }}
              />
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 500,
                  marginBottom: '0.25rem',
                  fontSize: '0.9rem',
                }}
              >
                {agent.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: '#6B7280',
                  flexGrow: 1,
                  fontSize: '0.75rem',
                }}
              >
                {agent.description}
              </Typography>
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation(); // Previene la propagazione del click al Paper
                  handleNewChat(agent.agentKey);
                  onClose();
                }}
                sx={{
                  marginTop: '0.75rem',
                  backgroundColor: '#DF4634',
                  color: '#FFFFFF',
                  borderRadius: '1rem',
                  textTransform: 'none',
                  fontSize: '0.75rem',
                  padding: '0.5rem 1rem',
                  '&:hover': {
                    backgroundColor: '#E57373',
                  },
                }}
              >
                Inizia
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AgentStart;

