import React, { useState, useEffect, forwardRef } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Alert,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Slide,
  TextField,
  Tooltip,
} from '@mui/material';
import Header from './Header';
import MobileMenu from './MobileMenu';
import SubscriptionPlan from './SubscriptionPlan';
import './styles/Subscription.css';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import useGetSubscription from '../api/subscription/useGetSubscription';
import useActivateFreeTrial from '../api/subscription/useActivateFreeTrial';
import useCreateCheckoutSession from '../api/subscription/useCreateCheckoutSession';
import useCustomerPortal from '../api/subscription/useCustomerPortal';
import useProcessVoucher from '../api/subscription/useProcessVoucher';
import useDeactivateStripeRenewal from '../api/subscription/useDeactivateStripeRenewal';
import useUpdateSubscriptionCartaDocente from '../api/subscription/UpdateSubscriptionCartaDocente';

import { loadStripe } from '@stripe/stripe-js';

import LoadingDots from './LoadingDots';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Subscription = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('access'));
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('free');

  const [openPaymentOptions, setOpenPaymentOptions] = useState(false);
  const [selectedPlanType, setSelectedPlanType] = useState(null);

  const [paymentStep, setPaymentStep] = useState('options');
  const [voucherCode, setVoucherCode] = useState('');
  
  // Aggiungi lo stato di caricamento specifico per il voucher
  const [isVoucherProcessing, setIsVoucherProcessing] = useState(false);

  const {
    subscription,
    refetch: refetchSubscription,
    loading: subscriptionLoading,
    error: subscriptionError,
  } = useGetSubscription();
  const {
    activateTrial,
    loading: activateLoading,
    error: activateError,
    message: activateMessage,
  } = useActivateFreeTrial();
  const {
    createSession,
    loading: createSessionLoading,
    error: createSessionError,
  } = useCreateCheckoutSession();
  const {
    openCustomerPortal,
    loading: portalLoading,
    error: portalError,
  } = useCustomerPortal();
  const {
    processVoucher,
    loading: voucherLoading,
    error: voucherError,
  } = useProcessVoucher();

  const { deactivateRenewal, loading: deactivateLoading, error: deactivateError } = useDeactivateStripeRenewal();
  const { updateSubscription, successMessage: updateSuccess, loading: updateLoading, error: updateError } = useUpdateSubscriptionCartaDocente();

  useEffect(() => {
    if (isLoggedIn) {
      refetchSubscription();
    }
  }, [isLoggedIn, refetchSubscription]);

  useEffect(() => {
    if (subscription && subscription.subscription_type) {
      setSelectedPlan(subscription.subscription_type);
    } else {
      setSelectedPlan('free');
    }
  }, [subscription]);

  const isCartaDocente = subscription?.payment_method_display === 'Carta Docente';

  const handleOpenPaymentOptions = (planType) => {
    if (!isLoggedIn) {
      window.location.href = '/welcomepage';
      return;
    }
    setSelectedPlanType(planType);
    setOpenPaymentOptions(true);
  };

  const handleClosePaymentOptions = () => {
    setOpenPaymentOptions(false);
    setSelectedPlanType(null);
    setPaymentStep('options');
  };

  const handlePaymentChoice = async (paymentType) => {
    if (paymentType === 'credit_card') {
      await proceedWithPurchase(selectedPlanType);
      handleClosePaymentOptions();
    } else if (paymentType === 'teacher_card') {
      setPaymentStep('voucher');
    }
  };

  const proceedWithPurchase = async (planType) => {
    try {
      if (planType === 'free') {
        await activateTrial();
        refetchSubscription();
        alert('La prova gratuita è stata attivata con successo!');
        return;
      }

      if (
        (subscription?.subscription_type === 'monthly' && planType === 'annual') ||
        (subscription?.subscription_type === 'annual' && planType === 'monthly')
      ) {
        await openCustomerPortal();
        return;
      }

      if (subscription?.subscription_type === planType && subscription.is_subscribed) {
        alert('Hai già questo piano attivo.');
        return;
      }

      const session = await createSession(planType);

      if (session) {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({ sessionId: session });

        if (error) {
          console.error('Errore durante il redirect a Stripe:', error.message);
          alert(`Errore durante il pagamento: ${error.message}`);
        }
      }
    } catch (error) {
      console.error('Errore durante l\'acquisto:', error);
      alert(`Errore durante l'acquisto: ${error.message || 'Si è verificato un errore.'}`);
    }
  };

  const handlePurchase = (planType) => {
    if (!isLoggedIn) {
      window.location.href = '/welcomepage';
      return;
    }
    if (planType === 'free') {
      proceedWithPurchase(planType);
    } else {
      handleOpenPaymentOptions(planType);
    }
  };

  const handleVoucherSubmit = async () => {
    setIsVoucherProcessing(true); // Inizia il caricamento
    try {
      // Step 1: Processa il voucher
      const importo = await processVoucher(voucherCode);
  
      // Step 2: Gestisci il pagamento con Carta Docente
      await handleCartaDocentePayment(importo);
  
      // Reset dei campi e chiusura del dialogo
      setVoucherCode('');
      setPaymentStep('options');
      handleClosePaymentOptions();
  
    } catch (err) {
      console.error('Errore durante il processo del voucher:', err);
      // Puoi gestire ulteriormente gli errori qui se necessario
    } finally {
      setIsVoucherProcessing(false); // Termina il caricamento
    }
  };
  
  const handleCartaDocentePayment = async (importo) => {
    try {
      // Step 1: Disattiva il rinnovo su Stripe
      await deactivateRenewal();
    } catch (error) {
      // Controlla se l'errore è un 400 e gestiscilo come desiderato
      if (error.response?.status === 400) {
        console.warn('Errore 400: Nessun abbonamento Stripe trovato o altro problema specifico. Continuo...');
        // Continua con i passaggi successivi senza interrompere
      } else {
        // Gestione di altri tipi di errori
        console.error('Errore nel pagamento con Carta Docente:', error);
        alert('Si è verificato un errore durante il pagamento con Carta Docente');
        throw error; // Rilancia l'errore per gestirlo nella funzione chiamante
      }
    }
  
    try {
      // Step 2: Attendi 15 secondi
      await new Promise((resolve) => setTimeout(resolve, 15000));
  
      // Step 3: Aggiorna l'abbonamento con l'importo
      await updateSubscription(importo);
  
      alert('Abbonamento aggiornato con successo con Carta Docente!');
      refetchSubscription();  // Ricarica le informazioni sull'abbonamento aggiornato
  
    } catch (error) {
      console.error('Errore nel pagamento con Carta Docente:', error);
      alert('Si è verificato un errore durante il pagamento con Carta Docente');
      throw error; // Rilancia l'errore per gestirlo nella funzione chiamante
    }
  };
  
  

  const freeFeatures = [
    { icon: <CheckCircleIcon sx={{ color: '#1976d2' }} />, text: 'Risorse Didattiche pronte di Minerva AI' },
    { icon: <CheckCircleIcon sx={{ color: '#1976d2' }} />, text: 'AI creazione attività didattiche' },
    { icon: <CheckCircleIcon sx={{ color: '#1976d2' }} />, text: 'AI ricerca nuove metodologie' },
    { icon: <CheckCircleIcon sx={{ color: '#1976d2' }} />, text: 'AI creazione quiz' },
    { icon: <CheckCircleIcon sx={{ color: '#1976d2' }} />, text: 'AI creazione laboratorio' },
    { icon: <CheckCircleIcon sx={{ color: '#1976d2' }} />, text: 'AI assistenza scrittura' },
    { icon: <CheckCircleIcon sx={{ color: '#1976d2' }} />, text: 'Metodo Montessori AI' },
    { icon: <CheckCircleIcon sx={{ color: '#1976d2' }} />, text: 'Metodo Reggio Emilia AI' },
    { icon: <CheckCircleIcon sx={{ color: '#1976d2' }} />, text: 'Accesso a caricamento dei file' },
    { icon: <CheckCircleIcon sx={{ color: '#1976d2' }} />, text: 'Funzionalità di download Word e PDF' },
    { icon: <CheckCircleIcon sx={{ color: '#1976d2' }} />, text: 'Funzionalità con limitazione di 75mila parole processate' },
  ];

  const monthlyFeatures = [
    ...freeFeatures.slice(0, 9),
    { icon: <CheckCircleIcon sx={{ color: '#1976d2' }} />, text: 'Funzionalità con limitazione di 750mila parole processate' },
  ];

  const annualFeatures = [
    ...monthlyFeatures.slice(0, 9),
    {
      icon: <CheckCircleIcon sx={{ color: '#1976d2' }} />,
      text: (
        <Typography sx={{ fontWeight: 'bold', fontSize: 'inherit' }}>
          Omaggio PDF della Quadagenda di https://maestrainbluejeans.com/
        </Typography>
      ),
    },
    {
      icon: <CheckCircleIcon sx={{ color: '#1976d2' }} />,
      text: (
        <Typography sx={{ fontWeight: 'bold', fontSize: 'inherit' }}>
          Omaggio Black Friday: "Christmas Coloring Book" 100 pagine natalizie pronte da stampare, colorare e ritagliare
        </Typography>
      ),
    },
    { icon: <CheckCircleIcon sx={{ color: '#1976d2' }} />, text: 'Funzionalità con limitazione di 750mila parole processate' },
  ];

  const planDescriptions = {
    free: 'Inizia con il nostro piano gratuito per esplorare le funzionalità di base.',
    monthly: 'Sottoscrivi il piano mensile per avere accesso completo alle funzionalità.',
    annual: 'Scegli il piano annuale e risparmia rispetto al pagamento mensile.',
  };

  const plans = [
    {
      title: 'Prova Gratuita 14 giorni',
      description: planDescriptions['free'],
      price: 'Gratis',
      features: freeFeatures,
      planType: 'free',
    },
    {
      title: 'Piano Mensile',
      description: planDescriptions['monthly'],
      price: '9,99 Euro al mese',
      features: monthlyFeatures,
      planType: 'monthly',
    },
    {
      title: 'Piano Annuale',
      description: planDescriptions['annual'],
      price: "99,99 Euro all'anno",
      features: annualFeatures,
      planType: 'annual',
    },
  ];

  const currentPlan = plans.find((plan) => plan.planType === selectedPlan);

  // Modifica lo stato di caricamento per includere altri loading se necessario
  const isLoading = subscriptionLoading || activateLoading || createSessionLoading || portalLoading || voucherLoading || deactivateLoading || updateLoading;

  if (!currentPlan) {
    return (
      <Box className="loading-container">
        <LoadingDots />
      </Box>
    );
  }

  const isPlanButtonDisabled = (planType) => {
    if (!subscription || !subscription.is_subscribed) {
      return false;
    }
    if (isCartaDocente && subscription.subscription_type !== planType) {
      return true;
    }
    return false;
  };

  // Funzione per generare gli stili dei bottoni
  const getButtonStyles = (planType) => ({
    backgroundColor: selectedPlan === planType ? '#DF4634' : 'white',
    border: '2px solid black',
    color: selectedPlan === planType ? 'white' : '#2B2E34',
    '&:hover': {
      backgroundColor: selectedPlan === planType ? '#DF4634' : '#f0f0f0',
      borderColor: '#DF4634',
      color: selectedPlan === planType ? 'white' : '#2B2E34',
    },
    '&:focus': {
      borderColor: '#DF4634',
      boxShadow: 'none',
    },
    '&:active': {
      borderColor: '#DF4634',
      boxShadow: 'none',
    },
    borderRadius: '1.875rem',
    textTransform: 'none',
    paddingX: '2rem',
    paddingY: '0.75rem',
    fontSize: '1rem',
    transition: 'background-color 0.3s ease, border-color 0.3s ease',
    boxShadow: 'none',
  });

  return (
    <Box className="subscription-container">
      <Header
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
        backgroundColor="#DF4634"
        textColor="#FFFFFF"
        logoImage="/logo/logobianco.svg"
      />

      {mobileMenuOpen && (
        <MobileMenu
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          setMobileMenuOpen={setMobileMenuOpen}
        />
      )}

      <Typography variant="h3" className="subscription-title">
        Scegli il tuo piano ideale
      </Typography>

      {/* Contenitore bianco a due colonne */}
      <Box className="subscription-content-container">
        {/* Colonna Sinistra */}
        <Box className="subscription-left-column">
          {/* Bottoni per selezionare il piano */}
          <ButtonGroup
            variant="outlined"
            aria-label="outlined primary button group"
            className="subscription-button-group"
          >
            {!(subscription?.subscription_type === 'monthly' || subscription?.subscription_type === 'annual') && (
              <Tooltip
                title={
                  isPlanButtonDisabled('free')
                    ? 'Non puoi cambiare piano durante un abbonamento attivo con Carta Docente.'
                    : ''
                }
              >
                <span>
                  <Button
                    onClick={() => setSelectedPlan('free')}
                    sx={getButtonStyles('free')}
                    disabled={isPlanButtonDisabled('free')}
                  >
                    Gratis
                  </Button>
                </span>
              </Tooltip>
            )}
            <Tooltip
              title={
                isPlanButtonDisabled('monthly')
                  ? 'Non puoi cambiare piano durante un abbonamento attivo con Carta Docente.'
                  : ''
              }
            >
              <span>
                <Button
                  onClick={() => setSelectedPlan('monthly')}
                  sx={getButtonStyles('monthly')}
                  disabled={isPlanButtonDisabled('monthly')}
                >
                  Mensile
                </Button>
              </span>
            </Tooltip>
            <Tooltip
              title={
                isPlanButtonDisabled('annual')
                  ? 'Non puoi cambiare piano durante un abbonamento attivo con Carta Docente.'
                  : ''
              }
            >
              <span>
                <Button
                  onClick={() => setSelectedPlan('annual')}
                  sx={getButtonStyles('annual')}
                  disabled={isPlanButtonDisabled('annual')}
                >
                  Annuale
                </Button>
              </span>
            </Tooltip>
          </ButtonGroup>

          {/* Dettagli del piano selezionato */}
          <SubscriptionPlan
            title={currentPlan.title}
            description={currentPlan.description}
            price={currentPlan.price}
            planType={currentPlan.planType} // Aggiungi questa linea
            onPurchase={() => handlePurchase(currentPlan.planType)}
            isActive={
              subscription?.subscription_type === currentPlan.planType &&
              subscription.is_subscribed
            }
            endDate={subscription?.subscription_end_date}
            paymentMethodDisplay={subscription?.payment_method_display}
            isPurchaseDisabled={
              isCartaDocente &&
              subscription?.subscription_type !== currentPlan.planType &&
              subscription.is_subscribed
            }
          />

          {/* Inserisci l'immagine qui */}
          <Box className="subscription-image-container">
            <img
              src="/subscription/carta_docente.png"
              alt="Carta Docente"
              className="subscription-image"
            />
          </Box>
        </Box>

        {/* Colonna Destra */}
        <Box className="subscription-right-column">
          <Typography variant="h6" className="subscription-features-title">
            Questo piano include:
          </Typography>

          <List className="subscription-features-list">
            {currentPlan.features.map((feature, index) => (
              <ListItem key={index} className="subscription-feature-item">
                <ListItemIcon className="subscription-feature-icon">
                  {feature.icon}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body2" className="subscription-feature-text">
                      {feature.text}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>

          {/* Inserisci condizionatamente l'immagine per il piano annuale */}
          {selectedPlan === 'annual' && (
            <Box className="subscription-annual-image-container">
              <img
                src="/subscription/quadagenda_cover.jpg"
                alt="Quadagenda Cover"
                className="subscription-annual-image"
              />
              <img
                src="/subscription/blackfriday.png"
                alt="Black Friday"
                className="subscription-blackfriday-image" // Classe CSS aggiuntiva
              />
            </Box>
          )}
        </Box>
      </Box>

      {/* Finestra di Dialogo per le Opzioni di Pagamento e il Voucher */}
      <Dialog
        open={openPaymentOptions}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClosePaymentOptions}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          className: 'subscription-dialog-paper',
        }}
      >
        <DialogTitle className="subscription-dialog-title">
          {paymentStep === 'options' ? 'Scegli Metodo di Pagamento' : 'Inserisci il tuo Codice Voucher'}
        </DialogTitle>

        {paymentStep === 'options' ? (
          <DialogActions className="subscription-dialog-actions">
            <Button
              onClick={() => handlePaymentChoice('credit_card')}
              className="subscription-dialog-button"
            >
              Carta di Credito
            </Button>
            <Button
              onClick={() => handlePaymentChoice('teacher_card')}
              className="subscription-dialog-button"
            >
              Carta Docente
            </Button>
          </DialogActions>
        ) : (
          <DialogContent className="subscription-dialog-content">
            <TextField
              label="Codice Voucher"
              value={voucherCode}
              onChange={(e) => setVoucherCode(e.target.value)}
              fullWidth
              required
              margin="normal"
            />
            <Button
              onClick={handleVoucherSubmit}
              variant="contained"
              color="primary"
              disabled={isVoucherProcessing} // Usa isVoucherProcessing per disabilitare il pulsante
              fullWidth
              className="subscription-dialog-submit-button"
            >
              {isVoucherProcessing ? <LoadingDots /> : 'Processa Voucher'} {/* Usa isVoucherProcessing per mostrare LoadingDots */}
            </Button>
            <Button
              onClick={() => setPaymentStep('options')}
              variant="text"
              color="primary"
              className="subscription-dialog-back-button"
            >
              Indietro
            </Button>
            {(voucherError || deactivateError || updateError) && (
              <Typography variant="body1" color="error.main" className="subscription-dialog-error">
                {voucherError?.error || deactivateError?.error || updateError?.error || 'Si è verificato un errore.'}
              </Typography>
            )}
          </DialogContent>
        )}
      </Dialog>

      {/* Gestisci loading e error */}
      {isLoading && (
        <Box className="loading-overlay">
          <LoadingDots />
        </Box>
      )}

      {/* Messaggi di Errore */}
      {isLoggedIn && (subscriptionError ||
        activateError ||
        createSessionError ||
        portalError) && (
        <Box className="error-alert">
          <Alert severity="error">
            {subscriptionError?.error ||
              activateError?.error ||
              createSessionError?.error ||
              portalError?.error ||
              'Si è verificato un errore.'}
          </Alert>
        </Box>
      )}

      {/* Messaggi di Successo */}
      {activateMessage && (
        <Box className="success-alert">
          <Alert severity="success">{activateMessage}</Alert>
        </Box>
      )}
    </Box>
  );
};

export default Subscription;
