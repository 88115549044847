// src/api/subscription/useActivateFreeTrial.js
import { useState } from 'react';
import apiClient from '../apiClient';

const useActivateFreeTrial = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const activateTrial = async () => {
    setLoading(true);
    setError(null);
    setMessage(null);
    try {
      const response = await apiClient.post('/api/accounts/activate-free-trial/');
      setMessage(response.data.message);
      return response.data;
    } catch (err) {
      setError(err.response?.data?.error || 'Errore nell\'attivazione della prova gratuita');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { activateTrial, loading, error, message };
};

export default useActivateFreeTrial;
