// src/api/documents/useGetDocument.js
import { useState, useCallback } from 'react';
import apiClient from '../apiClient';

const useGetDocument = () => {
  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Recupera un documento specifico per nome.
   *
   * @param {string} documentName - Il nome personalizzato del documento.
   * @returns {object} - I dati del documento recuperato.
   */
  const fetchDocument = useCallback(async (documentName) => {
    setLoading(true);
    setError(null);
    setDocument(null);
    try {
      const response = await apiClient.get(`/api/accounts/documents/${encodeURIComponent(documentName)}/`);
      setDocument(response.data);
      return response.data;
    } catch (err) {
      const errorMessage = err.response?.data?.error || 'Errore nel recupero del documento';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  }, []);

  return { document, loading, error, fetchDocument };
};

export default useGetDocument;
