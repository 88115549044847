// ReachedLimit.js
import React from 'react';
import { Button } from '@mui/material';

const ReachedLimit = ({ errorMessage, onClose }) => {
  return (
    <div
      style={{
        padding: '2rem',
        textAlign: 'center',
        backgroundColor: '#F3F6FB',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <p>{errorMessage}</p>
      <Button
        variant="contained"
        onClick={onClose}
        sx={{
          marginTop: '1rem',
          backgroundColor: '#DF4634',
          '&:hover': {
            backgroundColor: '#E57373',
          },
        }}
      >
        Chiudi
      </Button>
    </div>
  );
};

export default ReachedLimit;

