// src/components/FloatingMenu.js

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './styles/FloatingMenu.css';
import PropTypes from 'prop-types';
import useDeleteAccount from '../api/profile/useDeleteAccount';
import useSupportEmail from '../api/auth/useSupportEmail';
import apiClient from '../api/apiClient';
import { removeTokens } from '../api/authUtils';

// Importa i nuovi componenti modali
import DeleteAccountModal from './DeleteAccountModal';
import SupportModal from './SupportModal';

const FloatingMenu = ({ isLoggedIn, setIsLoggedIn }) => {
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [isIdle, setIsIdle] = useState(false); // Stato per l'inattività
  const navigate = useNavigate();

  const { deleteAccount, loading: deleteLoading, error: deleteError, success: deleteSuccess } = useDeleteAccount();

  const {
    sendSupportEmail,
    loading: supportLoading,
    error: supportError,
    message: supportMessage,
  } = useSupportEmail();

  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const response = await apiClient.get('/api/accounts/profile/');
        setUserEmail(response.data.email);
      } catch (error) {
        console.error('Errore nel recupero dell\'email utente:', error);
      }
    };

    if (isLoggedIn) {
      fetchUserEmail();
    }
  }, [isLoggedIn]);

  const handleLogout = () => {
    removeTokens();
    setIsLoggedIn(false);
    navigate('/', { replace: true });
  };

  const handleProfileClick = () => {
    setProfileMenuOpen(!profileMenuOpen);
  };

  const handleAccountDelete = async () => {
    try {
      await deleteAccount();
      setIsLoggedIn(false);
      navigate('/', { replace: true });
    } catch (err) {
      console.error('Errore nella cancellazione dell\'account:', err);
    }
  };

  const handleSupportSubmit = async (e) => {
    e.preventDefault();

    const message = e.target.message.value;

    if (!message) {
      return;
    }

    try {
      await sendSupportEmail(message);
      e.target.reset();
      setTimeout(() => {
        setSupportModalOpen(false);
      }, 2000);
    } catch (err) {
      // L'errore è già gestito nel custom hook
    }
  };

  // Logica per rilevare l'inattività dell'utente
  useEffect(() => {
    let timeout;

    const resetTimer = () => {
      setIsIdle(false);
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setIsIdle(true);
      }, 5000); // Tempo di inattività in millisecondi (es. 5 secondi)
    };

    // Eventi che consideriamo come attività dell'utente
    const events = ['mousemove', 'keydown', 'scroll', 'touchstart'];

    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    // Inizia il timer
    resetTimer();

    return () => {
      // Pulisci gli event listener e il timer
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
      clearTimeout(timeout);
    };
  }, []);

  // Se l'utente è inattivo, non mostrare il menu
  if (isIdle) {
    return null;
  }

  return (
    <div className="floating-menu">
      <nav className="floating-menu-nav">
        <Link to="/" className="floating-menu-item">Home</Link>
        <Link to="/resources" className="floating-menu-item">Risorse</Link>
        <Link to="/abbonamento" className="floating-menu-item">Abbonamento</Link>
        <Link to="/chat" className="floating-menu-item">Chat AI</Link>
        {isLoggedIn ? (
          <div className="profile-menu">
            <div className="profile-link" onClick={handleProfileClick}>
              <img src="/menu_icon/profile.png" alt="Profilo" className="profile-image" />
            </div>
            {profileMenuOpen && (
              <div className="profile-dropdown">
                <Link to="/profile" className="profile-item">
                  Profilo
                </Link>
                <Link to="/change-password" className="profile-item">
                  Cambia password
                </Link>
                <Link to="/change-card" className="profile-item">
                  Cambia carta
                </Link>
                <div className="profile-item" onClick={handleLogout}>
                  Esci
                </div>
              </div>
            )}
          </div>
        ) : (
          <Link to="/welcomepage" className="floating-menu-item">Accedi</Link>
        )}
      </nav>

      {/* Modale di conferma eliminazione account */}
      <DeleteAccountModal
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={handleAccountDelete}
        loading={deleteLoading}
        error={deleteError}
        success={deleteSuccess}
      />

      {/* Modale di supporto */}
      <SupportModal
        isOpen={supportModalOpen}
        onClose={() => setSupportModalOpen(false)}
        onSubmit={handleSupportSubmit}
        loading={supportLoading}
        error={supportError}
        message={supportMessage}
        userEmail={userEmail}
      />
    </div>
  );
};

FloatingMenu.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  setIsLoggedIn: PropTypes.func.isRequired,
};

export default FloatingMenu;
