// AgentStartMobile.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  IconButton,
  Divider,
  Dialog,
  Slide,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { keyframes } from '@emotion/react';

// Definizione degli agenti (puoi unire primaryAgents e secondaryAgents)
const agents = [
  {
    name: 'Metodologie',
    description: 'Scopri metodologie, con consigli su risorse e spiegazioni.',
    image: '/people/metodologie.png',
    agentKey: 'metodologie',
  },
  {
    name: 'Attività',
    description: 'Crea attività didattiche innovative per la tua classe.',
    image: '/people/attività.png',
    agentKey: 'attività',
  },
  {
    name: 'Quiz',
    description: 'Genera quiz stimolanti per diversi livelli di apprendimento.',
    image: '/people/quiz.png',
    agentKey: 'quiz',
  },
  {
    name: 'Laboratori e progetti',
    description: 'Perfetto per ideare e sviluppare laboratori o progetti stimolanti',
    image: '/people/laboratorio.png',
    agentKey: 'laboratori',
  },
  {
    name: 'Montessori',
    description: 'Scopri il Metodo Montessori per un apprendimento personalizzato.',
    image: '/people/montessori.png',
    agentKey: 'montessori',
  },
  {
    name: 'Malaguzzi',
    description: 'Approfondisci il Metodo Reggio Emilia per lo sviluppo creativo.',
    image: '/people/malaguzzi.png',
    agentKey: 'malaguzzi',
  },
  {
    name: 'Scrittura',
    description: 'Assistenza nella redazione di: UDA, Programmazioni, verbali e altro.',
    image: '/people/scrittura.png',
    agentKey: 'scrittura',
  },
];

// Animazione per i tre puntini
const loadingAnimation = keyframes`
  0% { opacity: 0.2; transform: translateY(0); }
  20% { opacity: 1; transform: translateY(-5px); }
  100% { opacity: 0.2; transform: translateY(0); }
`;

// Componente LoadingIndicator con i tre puntini animati
const LoadingIndicator = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          backgroundColor: '#E57373',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          margin: '0 5px',
          animation: `${loadingAnimation} 1s infinite`,
          animationDelay: '0s',
        }}
      />
      <Box
        sx={{
          backgroundColor: '#E57373',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          margin: '0 5px',
          animation: `${loadingAnimation} 1s infinite`,
          animationDelay: '0.2s',
        }}
      />
      <Box
        sx={{
          backgroundColor: '#E57373',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          margin: '0 5px',
          animation: `${loadingAnimation} 1s infinite`,
          animationDelay: '0.4s',
        }}
      />
    </Box>
  </Box>
);

// Funzione per precaricare le immagini
const preloadImages = (imageUrls) => {
  return Promise.all(
    imageUrls.map(
      (src) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        })
    )
  );
};

// Transizione per il Dialog
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AgentStartMobile = ({ open, onClose, handleNewChat }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (open) {
      // Raccogli tutti gli URL delle immagini
      const imageUrls = agents.map((agent) => agent.image);

      // Inizia il caricamento delle immagini
      preloadImages(imageUrls)
        .then(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Errore nel caricamento delle immagini:', error);
          setIsLoading(false); // Anche in caso di errore, prosegui per non bloccare l'interfaccia
        });
    } else {
      // Reset dello stato di caricamento quando il componente si chiude
      setIsLoading(true);
    }
  }, [open]);

  if (!open) return null;

  // Durante il caricamento, mostra solo l'animazione
  if (isLoading) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            backgroundColor: '#FFFFFF',
          },
        }}
      >
        <LoadingIndicator />
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          backgroundColor: '#FFFFFF',
          padding: '1rem',
        },
      }}
    >
      {/* Header con titolo e pulsante di chiusura */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '1rem',
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: 600, flexGrow: 1, textAlign: 'center' }}
        >
          Scegli l'esperto di cui hai bisogno
        </Typography>
        <IconButton onClick={onClose} sx={{ position: 'absolute', right: 10 }}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Lista degli agenti */}
      <Grid container spacing={1}>
        {agents.map((agent) => (
          <Grid item xs={12} key={agent.agentKey}>
            <Paper
              elevation={3}
              sx={{
                padding: '1rem',
                borderRadius: '1rem',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(223, 70, 52, 0.1)',
                },
              }}
              onClick={() => {
                handleNewChat(agent.agentKey);
                onClose();
              }}
            >
              <Box
                component="img"
                src={agent.image}
                alt={agent.name}
                sx={{
                  width: '3rem',
                  height: '3rem',
                  marginRight: '1rem',
                }}
              />
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 500, fontSize: '1rem' }}
                >
                  {agent.name}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: '#6B7280', fontSize: '0.85rem' }}
                >
                  {agent.description}
                </Typography>
              </Box>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#DF4634',
                  color: '#FFFFFF',
                  borderRadius: '1rem',
                  textTransform: 'none',
                  fontSize: '0.75rem',
                  '&:hover': {
                    backgroundColor: '#E57373',
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleNewChat(agent.agentKey);
                  onClose();
                }}
              >
                Inizia
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Dialog>
  );
};

export default AgentStartMobile;
